import { combineReducers } from 'redux';

import { createPersistedReducer } from '../../utils/createPersistedReducer';
import { createReducer } from '../../utils/createReducer';
import {
  visitChangeStatusRoutine,
  visitCommentsRoutine,
  visitDiagnozRoutine,
  visitsAddRoutine,
  visitSaveRoutine,
  visitsRoutine,
} from '../routines/visitsRoutine';

const persistConfig = {
  key: "visits",
  blacklist: ["visits", "visitsAdd", "visitDiagnoz"],
};

export default createPersistedReducer(
  combineReducers({
    visits: createReducer(visitsRoutine, "visits"),
    visitsAdd: createReducer(visitsAddRoutine, "visitsAdd"),
    visitSave: createReducer(visitSaveRoutine, "visitSave"),
    visitChangeStatus: createReducer(
      visitChangeStatusRoutine,
      "visitChangeStatus"
    ),
    visitComments: createReducer(visitCommentsRoutine, "visitComments"),
    visitDiagnoz: createReducer(visitDiagnozRoutine, "visitDiagnoz"),
  }),
  persistConfig
);
