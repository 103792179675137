import { createRoutine } from "redux-saga-routines";

export const userRoutine = createRoutine("API_USER_GET");
export const userLoginRoutine = createRoutine("API_USER_LOGIN");
export const userRegisterRoutine = createRoutine("API_USER_REGISTER");
export const userChangePasswordRoutine = createRoutine(
  "API_USER_CHANGE_PASSWORD"
);
export const userUpdateRoutine = createRoutine("API_USER_UPDATE");
export const userLogoutRoutine = createRoutine("API_USER_LOGOUT");
export const userForgotRoutine = createRoutine("API_USER_FORGOT");
export const userStatusRoutine = createRoutine("API_USER_STATUS");

export const userDepositRoutine = createRoutine("API_USER_DEPOSIT");
export const userWithdrawRoutine = createRoutine("API_USER_WITHDRAW");

export const userDepositHistoryRoutine = createRoutine(
  "API_USER_DEPOSIT_HISTORY"
);

export const userRefreshRoutine = createRoutine("API_USER_REFRESH");

export const userPincodeRoutine = createRoutine("API_USER_PINCODE");
export const userPincodeLoginRoutine = createRoutine("API_USER_PINCODE_LOGIN");

export const userSearchRoutine = createRoutine("API_USER_SEARCH");
