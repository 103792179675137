import { createSaga } from '../../utils/createSaga';
import {
  visitChangeStatusRoutine,
  visitCommentsRoutine,
  visitDiagnozRoutine,
  visitsAddRoutine,
  visitSaveRoutine,
  visitsRoutine,
} from '../routines/visitsRoutine';

export default function* anamnezSaga() {
  yield createSaga(visitsRoutine, "GET", "api/visits/");
  yield createSaga(visitsAddRoutine, "POST", "api/visits/");

  yield createSaga(visitSaveRoutine, "POST", "api/visit/save", {
    withFile: true,
  });

  yield createSaga(visitChangeStatusRoutine, "POST", "api/visit/changeStatus");

  yield createSaga(visitCommentsRoutine, "GET", "api/visit/comments");

  yield createSaga(visitDiagnozRoutine, "GET", "api/visit/diagnoz");
}
