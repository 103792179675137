import { createRoutine } from "redux-saga-routines";

export const visitsRoutine = createRoutine("API_VISITS_GET");
export const visitsAddRoutine = createRoutine("API_VISITS_ADD");
export const visitSaveRoutine = createRoutine("API_VISIT_SAVE");
export const visitChangeStatusRoutine = createRoutine(
  "API_VISIT_CHANGE_STATUS"
);

export const visitCommentsRoutine = createRoutine("API_VISIT_COMMENTS");
export const visitDiagnozRoutine = createRoutine("API_VISIT_DIAGNOZ");
