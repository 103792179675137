import "./style.scss";

import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Webcam} from "react-webcam-ultimate";

import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";

import {ANAMNEZ_LIFE} from "../../config/config";
import {AnamnezItem} from "../../pages/Anamnez/AnamnezItem";
import {anamnezLifeRoutine} from "../../redux/routines/anamnezRoutine";
import {externalPolisRoutine} from "../../redux/routines/externalRoutine";
import {hospitalRoutine} from "../../redux/routines/hospitalRoutine";
import {userSearchRoutine} from "../../redux/routines/userRoutine";
import {urlToFile} from "../../utils/urlToFile";

type Props = {
  patient: number | string;
  chats?: number;
  user?: any;
};

const gender = {
  male: "Мужской",
  female: "Женский",
};

export const PatientLeft = ({patient, chats = 0, user}: Props) => {
  const showPolisScanBtn = !user;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSearch = useSelector((state: any) => state.user.userSearch);
  const hospitals = useSelector((state: any) => state.hospital.hospitals);
  const externalPolis = useSelector((state: any) => state.external.polis);

  const anamnezLife = useSelector((state: any) => state.anamnez.anamnezLife);
  const [mainBlock, setMainBlock] = useState({});
  const [userChoice, setUserChoice] = useState({});

  const [pages, setPages] = useState(1);
  const [curPage, setCurPage] = useState(0);

  const [pagesBlocks, setPagesBlocks] = useState([]);
  const [showAnamnezRight, setShowAnamnezRight] = useState(false);

  const [alertPolis, setAlertPolis] = useState(false);

  const [hospitalsData, setHospitalsData] = useState([]);

  const handlePolisScan = () => {
    setAlertPolis(true);
    console.log("polis scan");
  };

  const onClosePolis = () => {
    setAlertPolis(false);
  };

  useEffect(() => {
    dispatch(hospitalRoutine());

    if (chats === 0) {
      dispatch(userSearchRoutine({id: patient}));
      dispatch(anamnezLifeRoutine({id: ANAMNEZ_LIFE, userId: patient}));
    }
  }, []);

  useEffect(() => {
    if (hospitals.isSuccess && hospitals?.data?.items) {
      let hospitalsData: any = {};

      hospitals?.data?.items.map((item) => {
        hospitalsData[item.id] = item.name;
      });

      setHospitalsData(hospitalsData);
    }
  }, [hospitals]);

  useEffect(() => {
    if (externalPolis.isSuccess && externalPolis?.data) {
      dispatch(externalPolisRoutine.fulfill());
      dispatch(userSearchRoutine({id: patient}));
    }
  }, [externalPolis]);

  useEffect(() => {
    let tempChoice = {};
    if (anamnezLife?.data?.items) {
      const data = anamnezLife?.data?.items;
      if (data.length && data[0]) {
        _.each(data[0].children, (item) => {
          const key = `code_${item.id}`;
          tempChoice = {...tempChoice, [key]: false};
        });
      }

      if (anamnezLife.data.userChoice) {
        tempChoice = {...tempChoice, ...anamnezLife.data.userChoice};
      }
      setUserChoice(tempChoice);

      const blocks = [data[0]];
      _.each(data[0].children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (tempChoice[key]) {
          if (item?.cond?.showChild != false) {
            _.each(item.children, (item2) => {
              const key2 = `code_${item2.id}`;
              //level 2
              if (tempChoice[key2] && item2.type == "checkbox" && item2.children && item2.children.length) {
                item2.parentName = item.name;
                blocks.push(item2);
              }
            });
          }
          if (item?.cond?.showChild == false) {
            blocks.push(item);
          }
        }
      });
      setPagesBlocks(blocks);
    }
  }, [anamnezLife]);
  user = user ? user : userSearch?.data?.user;
  const inside = user && (
    <>
      <p className="title">Пациент: ID {user?.code}</p>
      <p className="text">
        {user?.lastname} {user?.name} {user?.secondname}
      </p>
      <p className="text">Пол: {gender[user?.gender]}</p>
      <p className="text">Дата рождения: {moment(user?.birthday).format("DD.MM.YYYY")}г</p>
      <p className="text">{hospitalsData[user?.hospital]}</p>
      {user?.polis && <p className="text">Полис: {user?.polis}</p>}
      {chats > 0 && <span className="user-chats">+{chats}</span>}
      {showPolisScanBtn && (
        <>
          <button onClick={handlePolisScan} className="site-btn yellow btn-sm">
            Отсканировать полис
          </button>
          <Dialog open={alertPolis} onClose={onClosePolis} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{sx: {overflowY: "unset"}}}>
            <div style={{overflowY: "unset"}}>
              <DialogTitle id="alert-dialog-title">Сканирование полиса</DialogTitle>
              <DialogContent>
                <Webcam mirrored={false} style={{maxWidth: "100%", height: 400}}>
                  {({getSnapshot}) => (
                    <button
                      className="site-btn btn-sm"
                      onClick={async () => {
                        let result = getSnapshot({quality: 0.8});
                        console.log("@", result);
                        let file = await urlToFile(result, "polis.jpg");
                        dispatch(externalPolisRoutine({file, userId: user.id}));
                        setAlertPolis(false);
                      }}>
                      Сканировать
                    </button>
                  )}
                </Webcam>
              </DialogContent>
              <DialogActions>
                <IconButton
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: "-20px",
                    top: "-20px",
                    backgroundColor: "lightgray",
                    color: "gray",
                    "&:hover": {
                      backgroundColor: "lightgray",
                    },
                  }}
                  onClick={onClosePolis}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </div>
          </Dialog>
        </>
      )}
    </>
  );

  return (
    <>
      {chats == 0 && (
        <>
          <div className="block user-block">{inside}</div>
          {pagesBlocks.map((pageBlock, index) => {
            const key = `code_${pageBlock.id}`;
            const text = (item, itemIndex = 1) => {
              let returnText = "";
              const key = `code_${item.id}`;
              if (item.children.length > 0) {
                let visibleIndex = 0;
                _.each(item.children, (item2, index2) => {
                  const key2 = `code_${item2.id}`;
                  //level 2
                  if (item2.type == "s-block") {
                    returnText += text(item2);
                  } else if (userChoice[key2]) {
                    if (item2.children.length > 0) {
                      if (item2.type == "select") {
                        returnText += `${item2.name}: ${userChoice[key2]}`;
                      } else {
                        returnText += `${item2.type != "selectbox" && visibleIndex > 0 ? "<br>" : ""}${item2.name}:${item2.type == "selectbox" ? " " : "<br>"}` + text(item2, itemIndex + 1);
                      }
                    } else {
                      if (item2.type != "checkbox") {
                        returnText += `${item2.name}: ${userChoice[key2]}${Number(index2) != item.children.length - 1 ? "<br>" : ""}`;
                      } else {
                        returnText += `${item2.name} `;
                      }
                    }
                    if (userChoice[key2]) {
                      visibleIndex++;
                    }
                  }
                });
              } else {
                if (userChoice[key]) {
                  if (item.type != "checkbox") {
                    returnText += `${item.name}: ${userChoice[key]}<br>`;
                  } else {
                    returnText += `${item.name} `;
                  }
                }
              }
              return returnText;
            };

            if (index == 0) {
              const items = pageBlock.children.map((item) => {
                if (item.cond && item.cond.showMain) {
                  const resultItem = {
                    title: item.type == "checkbox" ? item.name : "",
                    text: text(item),
                  };
                  if (resultItem.text) {
                    return <AnamnezItem key={`code_${item.id}`} item={resultItem} index={index} style={{marginRight: 16}} onSelect={() => navigate(`/patient/${patient}/anamnez/${anamnezLife?.data?.anamnezId}/${index}`)} />;
                  }
                }
              });
              return items;
            }
            if (userChoice[key]) {
              const resultItem = {
                title: pageBlock.name,
                text: text(pageBlock),
              };
              return <AnamnezItem key={key} item={resultItem} index={index} style={{marginRight: 16}} onSelect={() => navigate(`/patient/${patient}/anamnez/${anamnezLife?.data?.anamnezId}/${index}`)} />;
            }
          })}
        </>
      )}
      {chats > 0 && (
        <div className="block user-block" onClick={() => navigate(`/patient/${user?.id}`)}>
          {inside}
        </div>
      )}
    </>
  );
};
