export interface IRemoteData<T = null> {
  data: T;
  error?: ApiError;
  isSuccess: boolean;
  isFailure: boolean;
  isRequest: boolean;
  isInitial: boolean;
  errorMessage?: string;
}

export interface ApiResponse {
  message: string;
  code: number;
}

export interface ApiError {
  error: string | ApiResponse;
  error_more: string;
  error_readable: string;
  error_field: string | null;
}

export const RemoteData = {
  success: (data: any): IRemoteData => {
    return {
      data,
      isSuccess: true,
      isFailure: false,
      isRequest: false,
      isInitial: false,
    };
  },
  failure: (error: ApiError): IRemoteData => {
    let errorMessage, mess;
    if (error && typeof error !== "string" && "error" in error) mess = (error?.error as ApiResponse)?.message;
    errorMessage = error.error_readable || error.error_more || mess || error.error;
    return {
      data: null,
      error,
      errorMessage,
      isSuccess: false,
      isFailure: true,
      isRequest: false,
      isInitial: false,
    };
  },
  initial: (): IRemoteData => {
    return {
      data: null,
      error: null,
      isSuccess: false,
      isFailure: false,
      isRequest: false,
      isInitial: true,
    };
  },
  request: (): IRemoteData => {
    return {
      data: null,
      isSuccess: false,
      isFailure: false,
      isRequest: true,
      isInitial: false,
    };
  },
};
