import "./style.scss";
import "moment/locale/ru";

import React, {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from "react";

import {Field, Form, Formik} from "formik";
import _ from "lodash";
import moment from "moment";
import {useSnackbar} from "notistack";
import {useDropzone} from "react-dropzone";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";
import {Webcam} from "react-webcam-ultimate";

import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, MenuItem} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import ChatSound from "../../assets/audio/chat.mp3";
import CalendarIcon from "../../assets/images/calendar-ico.svg";
import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {ANAMNEZ_DIAGNOZ, ANAMNEZ_HEALTH, config} from "../../config/config";
import {anamnezDiagnozRoutine, anamnezRoutine} from "../../redux/routines/anamnezRoutine";
import {chatRoutine, chatSendRoutine} from "../../redux/routines/chatRoutine";
import {externalGorloRoutine, externalHripyRoutine} from "../../redux/routines/externalRoutine";
import {visitChangeStatusRoutine, visitCommentsRoutine, visitDiagnozRoutine, visitSaveRoutine, visitsRoutine} from "../../redux/routines/visitsRoutine";
import {urlToFile} from "../../utils/urlToFile";
import {AnamnezItem} from "../Anamnez/AnamnezItem";
import {Chat} from "./Chat";

moment.locale("ru");

type Props = {};

export const VisitDetailPage = (props: Props) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  let params = useParams();
  const visitId = Number.parseInt(params.id);

  const visits = useSelector((state: any) => state.visits.visits);
  const visitSave = useSelector((state: any) => state.visits.visitSave);
  const visitChangeStatus = useSelector((state: any) => state.visits.visitChangeStatus);
  const visitComments = useSelector((state: any) => state.visits.visitComments);
  const anamnez = useSelector((state: any) => state.anamnez.anamnez);
  const anamnezDiagnoz = useSelector((state: any) => state.anamnez.anamnezDiagnoz);
  const possibleDiagnoz = useSelector((state: any) => state.visits.visitDiagnoz);

  const gorloResult = useSelector((state: any) => state.external.gorlo);
  const hripyResult = useSelector((state: any) => state.external.hripy);

  const [diagnoz, setDiagnoz] = useState({});
  const [d1, setD1] = useState([]);
  const [d2, setD2] = useState([]);
  const [d3, setD3] = useState([]);

  const [isChat, showChat] = useState(false);

  const [alertGorlo, setAlertGorlo] = useState(false);

  const customInputRef = useRef<HTMLInputElement>();

  const [messages, setMessages] = useState([]);
  const m = useSelector((state: any) => state.chats.chats);
  const chat = useSelector((state: any) => state.chats.chatSend);
  const user = useSelector((state: any) => state.user.user);

  const [newMessCount, setNewMessCount] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [commentFileName, setFileName] = useState("");

  useEffect(() => {
    dispatch(chatRoutine({visitId}));
    dispatch(visitDiagnozRoutine({visitId}));

    return () => {
      dispatch(chatRoutine.fulfill());
      dispatch(chatSendRoutine.fulfill());
    };
  }, [visitId]);

  useEffect(() => {
    if (m.isSuccess && m?.data?.items) {
      setMessages(m?.data?.items);
      let count = m?.data?.items.reduce((res, item) => {
        if (item.userId != user?.data?.id && item.status == "new") {
          return res + 1;
        }
        return res;
      }, 0);
      setNewMessCount(count);
    }
  }, [m]);

  useEffect(() => {
    if (chat.isSuccess && chat?.data?.chat) {
      if (chat?.data?.chat.status == "new" && chat?.data?.chat.userId !== user?.data?.id) {
        setNewMessCount(newMessCount + 1);
        new Audio(ChatSound).play();
      }
      setMessages([...messages, chat?.data?.chat]);
    }
  }, [chat]);

  const [visit, setVisit] = useState({
    id: "",
    doctorId: "",
    date: "",
    diagnoz: "",
    diagnozData: {d1: "", d2: "", d3: ""},
    diagnozComment: "",
    therapy: "",
    userChoice: {},
    osmotr: "",
    repeat: "",
    status: "",
    measures: {} as any,
    gorloFile: "",
    gorloResult: "",
    hripyFile: "",
    hripyResult: "",
  });

  const [dateRepeatOpen, setDateRepeatOpen] = useState(false);

  useEffect(() => {
    dispatch(anamnezRoutine({id: ANAMNEZ_HEALTH}));
    dispatch(anamnezDiagnozRoutine({id: ANAMNEZ_DIAGNOZ}));
    dispatch(visitCommentsRoutine({visitId}));

    if (visits.data?.items) {
      let visitt = visits.data?.items.find((item) => item.id === visitId);
      setVisit(visitt);
    } else {
      dispatch(visitsRoutine({userId: params.userId}));
    }

    return () => {
      dispatch(visitCommentsRoutine.fulfill());
      dispatch(visitSaveRoutine.fulfill());
      dispatch(visitChangeStatusRoutine.fulfill());
    };
  }, [visits]);

  useEffect(() => {
    if (visits.data?.items) {
      let visitt = visits.data?.items.find((item) => item.id === visitId);
      if (visitt.diagnozData) {
        handleDiagnoz(visitt.diagnozData);
      }

      setVisit(visitt);
    }
  }, [visits]);

  const [userChoice, setUserChoice] = useState({});

  const [pagesBlocks, setPagesBlocks] = useState([]);

  useEffect(() => {
    let tempChoice = {};
    if (anamnez?.data?.items) {
      const data = anamnez?.data?.items;
      if (data.length && data[0]) {
        _.each(data[0].children, (item) => {
          const key = `code_${item.id}`;
          tempChoice = {...tempChoice, [key]: false};
        });
      }

      if (visit.userChoice) {
        tempChoice = {...tempChoice, ...visit.userChoice};
      }
      setUserChoice(tempChoice);

      const blocks = [data[0]];
      _.each(data[0].children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (tempChoice[key]) {
          if (item?.cond?.showChild != false) {
            _.each(item.children, (item2) => {
              const key2 = `code_${item2.id}`;
              //level 2
              if (tempChoice[key2] && item2.type == "checkbox" && item2.children && item2.children.length) {
                item2.parentName = item.name;
                blocks.push(item2);
              }
            });
          }
          if (item?.cond?.showChild == false) {
            blocks.push(item);
          }
        }
      });
      setPagesBlocks(blocks);
    }
  }, [anamnez, visit]);

  useEffect(() => {
    if (gorloResult.isSuccess) {
      setVisit({...visit, ...gorloResult.data?.visit});
      console.log(gorloResult);

      enqueueSnackbar("Данные сохранены", {variant: "success"});
      dispatch(externalGorloRoutine.fulfill());
    }
    if (hripyResult.isSuccess) {
      setVisit({...visit, ...hripyResult.data?.visit});
      enqueueSnackbar("Данные сохранены", {variant: "success"});
      dispatch(externalHripyRoutine.fulfill());
    }
  }, [gorloResult, hripyResult]);

  const handleDiagnoz = (values) => {
    let d2temp = [];
    let d3temp = [];
    anamnezDiagnoz?.data?.items[0]?.children.map((item) => {
      if (values.d1) {
        if (item.name == values.d1 && item.children.length > 0) {
          item.children.map((item2) => {
            d2temp.push({id: item2.id, value: item2.name});
            if (values.d2) {
              if (item2.name == values.d2 && item2.children.length > 0) {
                item2.children.map((item3) => {
                  d3temp.push({id: item3.id, value: item3.name});
                });
              }
            }
          });
        }
      }
    });
    setD2(d2temp);
    setD3(d3temp);
  };

  const handleCloseVisit = () => {
    dispatch(visitChangeStatusRoutine({visitId, status: "closed"}));
  };

  useEffect(() => {
    if (anamnezDiagnoz.isSuccess && anamnezDiagnoz?.data?.items && anamnezDiagnoz?.data?.items[0]?.children) {
      let d1temp = [];
      anamnezDiagnoz?.data?.items[0]?.children.map((item) => {
        d1temp.push({id: item.id, value: item.name});
      });
      setD1(d1temp);
    }
  }, [anamnezDiagnoz]);

  useEffect(() => {
    if (visitSave.isSuccess) {
      enqueueSnackbar("Данные сохранены", {variant: "success"});
      dispatch(visitSaveRoutine.fulfill());
    }
    dispatch(visitCommentsRoutine({visitId}));
  }, [visitSave]);

  useEffect(() => {
    if (visitChangeStatus.isSuccess) {
      enqueueSnackbar("Статус изменен", {variant: "success"});
      dispatch(visitChangeStatusRoutine.fulfill());
    }
  }, [visitChangeStatus]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log("@", acceptedFiles);

    dispatch(externalHripyRoutine({file: acceptedFiles, visitId}));
  }, []);
  const {acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} = useDropzone({accept: {"application/pdf": []}, onDropAccepted: onDrop, maxFiles: 1});

  const handleGorlo = () => {
    setAlertGorlo(true);
  };
  const onCloseGorlo = () => {
    setAlertGorlo(false);
  };

  const baseStyle: React.CSSProperties = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#67b3e2",
    color: "#fff",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style: CSSProperties = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const files = acceptedFiles.map((file, index) => (
    <p className="comment-file" key={`file_${index}`}>
      {file.name
        .split(/[\\/]/)
        .pop()
        .replace(/-[\d]+(\..+)$/, "$1")}
    </p>
  ));

  return (
    <div className="visit-detail">
      <Scrollbar>
        <Formik
          enableReinitialize
          initialValues={{
            osmotr: visit.osmotr,
            possibleDiagnoz: "",
            d1: visit.diagnozData?.d1,
            d2: visit.diagnozData?.d2,
            d3: visit.diagnozData?.d3,
            diagnozComment: visit.diagnozComment,
            therapy: visit.therapy,
            repeat: visit.repeat,
            comment: "",
            fileComment: null,
            gorlo1: visit?.gorloResult,
            gotrlo2: "",
            hripy: visit?.hripyResult ? true : false,
            hripyResult: visit?.hripyResult,
          }}
          onSubmit={(values) => {
            values["visitId"] = visitId;
            dispatch(visitSaveRoutine(values));

            values["comment"] = "";
            values["fileComment"] = null;
            setFileName("");
          }}>
          {({values, errors, handleChange, handleBlur, setFieldValue}) => {
            return (
              <Form>
                <div className="visit-block-inner">
                  <div className="visit-block-left">
                    <label className="field-title">
                      Обращение {visit.id} от {moment(visit.date).format("DD.MM.YYYY")}
                    </label>
                    {pagesBlocks.map((pageBlock, index) => {
                      const key = `code_${pageBlock.id}`;
                      const text = (item, itemIndex = 1) => {
                        let returnText = "";
                        const key = `code_${item.id}`;
                        if (item.children.length > 0) {
                          let visibleIndex = 0;
                          if (item.type == "s-block") returnText += "<ul>";
                          _.each(item.children, (item2, index2) => {
                            const key2 = `code_${item2.id}`;
                            //level 2
                            if (item2.type == "s-block" || item2.type == "s-block-radio") {
                              const blockText = text(item2);
                              if (blockText) {
                                returnText += `${item2.name}:${item2.type == "s-block-radio" ? " " : "<br />"}${blockText}${Number(index2) != item.children.length - 1 ? "<br />" : "<br />"}`;
                              }
                            } else if (userChoice[key2]) {
                              if (item2.children.length > 0) {
                                if (item2.type == "select") {
                                  returnText += `${item2.name}: ${userChoice[key2]}<br />`;
                                } else {
                                  if (item.type == "s-block") returnText += `<li>${item2.name}: ${text(item2, itemIndex + 1)}</li>`;
                                  else
                                    returnText +=
                                      `${item2.type != "selectbox" && item2.type != "radiobox" && visibleIndex > 0 ? "<br />" : ""}${item2.name}:${item2.type == "s-block-radio" || item2.type == "selectbox" || item2.type == "radiobox" ? " " : "<br />"}` +
                                      text(item2, itemIndex + 1);
                                }
                              } else {
                                if (item2.type != "checkbox" && item2.type != "radio") {
                                  if (item.type == "s-block") returnText += `<li>${item2.name}: ${userChoice[key2]}$</li>`;
                                  else returnText += `${item2.name}: ${userChoice[key2]}${Number(index2) != item.children.length - 1 ? "<br />" : "<br />"}`;
                                } else {
                                  if (item.type == "s-block") returnText += `<li>${item2.name}</li>`;
                                  else returnText += `${item2.name}${Number(index2) != item.children.length - 1 ? ", " : ""}`;
                                }
                              }
                              if (userChoice[key2]) {
                                visibleIndex++;
                              }
                            }
                          });
                          if (item.type == "s-block") returnText += "</ul>";
                        } else {
                          if (userChoice[key]) {
                            if (item.type != "checkbox" && item.type != "radio") {
                              returnText += `${item.name}: ${userChoice[key]}<br />`;
                            } else {
                              returnText += `${item.name} `;
                            }
                          }
                        }
                        return returnText;
                      };

                      if (index == 0) {
                        const items = pageBlock.children.map((item) => {
                          if (item.cond && item.cond.showMain) {
                            const resultItem = {
                              title: item.type == "checkbox" || item.type == "radio" ? item.name : "",
                              text: text(item),
                            };
                            if (resultItem.text) {
                              return <AnamnezItem key={`code_${item.id}`} item={resultItem} index={index} />;
                            }
                          }
                        });
                        return items;
                      }
                      if (userChoice[key]) {
                        const resultItem = {
                          title: pageBlock.name,
                          text: text(pageBlock),
                        };
                        return <AnamnezItem key={key} item={resultItem} index={index} />;
                      }
                    })}
                    <label className="field-title" style={{marginTop: 30}}>
                      Ваш комментарий к анамезу болезни
                    </label>
                    <Field name="comment" type="textarea" placeholder="Сообщение" component={Input}></Field>
                    <div className="row" style={{display: "flex"}}>
                      <Button type="button">
                        <label>
                          Прикрепить файл
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(event) => {
                              setFieldValue("fileComment", event.currentTarget.files[0]);
                              setFileName(event.currentTarget.files[0].name);
                            }}
                          />
                        </label>
                      </Button>
                      <Button type="submit" variant="yellow" style={{marginLeft: "auto"}}>
                        Отправить
                      </Button>
                    </div>
                    {commentFileName != "" && (
                      <div style={{marginTop: 10}}>
                        <p style={{color: "#fff"}}>{commentFileName}</p>
                      </div>
                    )}
                    <label className="field-title" style={{marginTop: 45}}>
                      Комментарии к анамезу
                    </label>
                    {visitComments.isSuccess && visitComments.data?.items && (
                      <List className="comment-list">
                        {visitComments.data?.items.map((item) => (
                          <ListItem className="comment-item">
                            <p className="comment-title">
                              <span className="comment-user">{[item.user.lastname, item.user.name, item.user.secondname].join(" ")}</span>
                              <span className="comment-date">{moment(item.date).format("DD MMMM YYYY")}</span>
                            </p>
                            <p className="comment-text">{item.comment}</p>
                            {item.file && (
                              <p className="comment-file">
                                <span>Файлы</span>{" "}
                                <a href={`${config.domen}${item.file}`} target="_blank" rel="noreferrer">
                                  {item.file
                                    .split(/[\\/]/)
                                    .pop()
                                    .replace(/-[\d]+(\..+)$/, "$1")}
                                </a>
                              </p>
                            )}
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </div>
                  <div className="visit-block-right">
                    {isChat ? (
                      <Chat
                        visitId={visitId}
                        messages={messages}
                        goBack={() => {
                          showChat(false);
                          setNewMessCount(0);
                        }}
                      />
                    ) : (
                      <>
                        {visit.measures && (
                          <>
                            <label className="field-title">Данные обследования</label>
                            <div className="anamnez-card">
                              <div className="anamnez-titleRow">
                                <p className="anamnez-cardText anamnez-cardTitle">Дата обновления данных: {moment(visit.measures?.date).format("DD.MM.YYYY HH:mm")}</p>
                              </div>
                              {visit.measures?.temperature && <p className="anamnez-cardText">Температура: {visit.measures?.temperature}</p>}
                              {visit.measures?.pulse && <p className="anamnez-cardText">Пульс: {visit.measures?.pulse}</p>}
                              {visit.measures?.sys && <p className="anamnez-cardText">Давление систолическое: {visit.measures?.sys}</p>}
                              {visit.measures?.dia && <p className="anamnez-cardText">Давление диастолическое: {visit.measures?.dia}</p>}

                              {visit.measures?.height && <p className="anamnez-cardText">Рост: {visit.measures?.height}</p>}
                              {visit.measures?.weight && <p className="anamnez-cardText">Вес: {visit.measures?.weight}</p>}
                              {visit.measures?.imt && <p className="anamnez-cardText">ИМТ: {visit.measures?.imt}</p>}

                              {visit.measures?.oxi && <p className="anamnez-cardText">Насыщение крови кислородом: {visit.measures?.oxi}</p>}
                              {visit.measures?.dynamometer_right && <p className="anamnez-cardText">Динамометр(правая): {visit.measures?.dynamometer_right}</p>}
                              {visit.measures?.dynamometer_left && <p className="anamnez-cardText">Динамометр(левая): {visit.measures?.dynamometer_left}</p>}
                            </div>
                          </>
                        )}
                        <label className="field-title">Осмотр</label>
                        <Field name="osmotr" type="textarea" placeholder="Укажите ход осмотра" component={Input}></Field>
                        <label className="field-title small">Осмотр горла</label>
                        <Field name="gorlo1" type="input" placeholder="Состояние горла" component={Input}></Field>
                        <Field name="gorlo2" type="input" placeholder="Заболевание" component={Input}></Field>
                        <button className="site-btn btn-sm" onClick={handleGorlo} type="button">
                          Обследовать горло
                        </button>
                        <br />
                        <br />
                        <Dialog open={alertGorlo} onClose={onCloseGorlo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{sx: {overflowY: "unset"}}}>
                          <div style={{overflowY: "unset"}}>
                            <DialogTitle id="alert-dialog-title">Обследовать горло</DialogTitle>
                            <DialogContent>
                              <Webcam mirrored={false} style={{maxWidth: "100%", height: 400}}>
                                {({getSnapshot}) => (
                                  <button
                                    className="site-btn btn-sm"
                                    onClick={async () => {
                                      let result = getSnapshot({quality: 0.8});
                                      console.log("@", result);

                                      let file = await urlToFile(result, "gorlo.jpg");
                                      dispatch(externalGorloRoutine({file, visitId}));
                                      setAlertGorlo(false);
                                    }}>
                                    Обследовать
                                  </button>
                                )}
                              </Webcam>
                            </DialogContent>
                            <DialogActions>
                              <IconButton
                                color="primary"
                                sx={{
                                  position: "absolute",
                                  right: "-20px",
                                  top: "-20px",
                                  backgroundColor: "lightgray",
                                  color: "gray",
                                  "&:hover": {
                                    backgroundColor: "lightgray",
                                  },
                                }}
                                onClick={onCloseGorlo}>
                                <CloseIcon />
                              </IconButton>
                            </DialogActions>
                          </div>
                        </Dialog>
                        <label className="field-title small">Хрипы</label>
                        <Field name="hripy" type="checkbox" placeholder="Заболевание" inputProps={{item: {name: "Присутствуют хрипы"}}} component={Input}></Field>
                        {values.hripy && (
                          <>
                            <section className="container">
                              <div {...getRootProps({style})}>
                                <input {...getInputProps()} />
                                <p>Прикрепить</p>
                              </div>
                            </section>

                            {visit.hripyFile && (
                              <div className="comment-list">
                                <p className="comment-file">
                                  <a href={`${config.domen}${visit.hripyFile}`} target="_blank" rel="noreferrer">
                                    {visit.hripyFile
                                      .split(/[\\/]/)
                                      .pop()
                                      .replace(/-[\d]+(\..+)$/, "$1")}
                                  </a>
                                </p>
                              </div>
                            )}
                            <Field name="hripyResult" type="input" placeholder="Результат" component={Input}></Field>
                          </>
                        )}
                        <br />

                        {possibleDiagnoz.isSuccess && possibleDiagnoz?.data?.result && (
                          <>
                            <label className="field-title small">Возможный диагноз</label>
                            <Field
                              name="possibleDiagnoz"
                              type="select"
                              value={values.possibleDiagnoz}
                              component={Input}
                              onChange={async (e) => {
                                let target = e.target as HTMLButtonElement;
                                let d: any = Object.values(possibleDiagnoz?.data?.result)[parseInt(target.value) - 1];
                                let vals = {...values};
                                vals["d1"] = d.diagnozData.d1;
                                vals["d2"] = d.diagnozData.d2;
                                vals["d3"] = d.diagnozData.d3;
                                setFieldValue("d1", vals["d1"]);
                                setFieldValue("d2", vals["d2"]);
                                setFieldValue("d3", vals["d3"]);
                                handleDiagnoz(vals);
                              }}>
                              {Object.values(possibleDiagnoz?.data?.result).map((item: any, index) => (
                                <MenuItem key={`pd_${index}`} value={index + 1}>
                                  {item?.diagnoz ? `${item?.diagnoz} ${item?.compare}%` : ""}
                                </MenuItem>
                              ))}
                            </Field>
                          </>
                        )}
                        <label className="field-title">Установите диагноз</label>
                        <Field
                          name="d1"
                          type="select"
                          value={values.d1}
                          placeholder="По типу"
                          component={Input}
                          onChange={async (e) => {
                            let target = e.target as HTMLButtonElement;

                            let vals = {...values};
                            vals[target.name] = target.value;
                            setFieldValue("d2", "");
                            setFieldValue("d3", "");
                            vals["d2"] = "";
                            vals["d3"] = "";
                            handleDiagnoz(vals);
                          }}>
                          {d1.map((item, i) => (
                            <MenuItem key={`d1_${item.id}`} value={item.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Field>
                        {d2.length > 0 && (
                          <Field
                            name="d2"
                            type="select"
                            value={values.d2}
                            placeholder="Заболевание"
                            component={Input}
                            onChange={async (e) => {
                              let target = e.target as HTMLButtonElement;
                              let vals = {...values};
                              vals[target.name] = target.value;
                              vals["d3"] = "";
                              setFieldValue("d3", "");
                              handleDiagnoz(vals);
                            }}>
                            {d2.map((item, i) => (
                              <MenuItem key={`d2_${item.id}`} value={item.value}>
                                {item.value}
                              </MenuItem>
                            ))}
                          </Field>
                        )}
                        {d3.length > 0 && (
                          <Field name="d3" type="select" value={values.d3} placeholder="Уточнение заболевания" component={Input}>
                            {d3.map((item, i) => (
                              <MenuItem key={`d3_${item.id}`} value={item.value}>
                                {item.value}
                              </MenuItem>
                            ))}
                          </Field>
                        )}

                        <Field name="diagnozComment" type="textarea" placeholder="Комментарий к диагнозу" component={Input} style={{height: 126}}></Field>
                        <label className="field-title">Лечение</label>
                        <Field name="therapy" type="textarea" placeholder="Лечение" component={Input}></Field>

                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
                          <DatePicker
                            open={dateRepeatOpen}
                            onOpen={() => setDateRepeatOpen(true)}
                            onClose={() => setDateRepeatOpen(false)}
                            value={values.repeat ? moment(values.repeat, "DD.MM.YYYY") : moment()}
                            PopperProps={{anchorEl: customInputRef.current}}
                            onChange={(newValue) => {
                              setFieldValue("repeat", newValue ? moment(newValue).format("DD.MM.YYYY") : "");
                              if (customInputRef?.current) customInputRef?.current.blur();
                            }}
                            renderInput={(params) => (
                              <div className="form-control-row">
                                <input
                                  ref={customInputRef}
                                  name="repeat"
                                  type="text"
                                  value={values.repeat}
                                  placeholder="Повторная явка"
                                  className="form-control"
                                  autoComplete="new-password"
                                  onBlur={handleBlur}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setDateRepeatOpen(true);
                                  }}
                                  onChange={(newValue) => {
                                    setFieldValue("repeat", (newValue.target as HTMLInputElement).value);
                                    if (customInputRef?.current) customInputRef?.current.blur();
                                  }}
                                />
                                <div className="form-control-icon">
                                  <img src={CalendarIcon} alt="" />
                                </div>
                              </div>
                            )}
                            dayOfWeekFormatter={(day) => `${day}.`}
                          />
                        </LocalizationProvider>

                        <div className="buttons-row">
                          {visit.status != "closed" && visit.doctorId == user?.data?.id && (
                            <>
                              <Button
                                type="button"
                                onClick={() => {
                                  showChat(true);
                                }}>
                                Чат с пациентом
                                {newMessCount > 0 && <span className="count">+{newMessCount}</span>}
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  handleCloseVisit();
                                }}>
                                Пациент здоров
                              </Button>
                            </>
                          )}
                        </div>
                        <Button variant="yellow" type="submit">
                          Сохранить
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Scrollbar>
    </div>
  );
};
