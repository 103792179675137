import './style.scss';

import React from 'react';

import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import { Button } from '../Button';
import { LeftChats } from '../LeftChats';
import { PatientLeft } from '../PatientLeft';

type Props = {};

const gender = {
  male: "Мужской",
  female: "Женский",
};

export const LeftSide = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const matchPatient =
    matchPath({ path: "/patient/:id" }, location.pathname) ||
    matchPath({ path: "/patient/:id/visit/:visitId" }, location.pathname) ||
    matchPath(
      { path: "/patient/:id/anamnez/:userAnamnez/:index" },
      location.pathname
    );

  return (
    <>
      <div className="left-side">
        {matchPatient ? (
          <>
            <div className="user-anamnez-block">
              <Scrollbar>
                <PatientLeft patient={matchPatient.params.id} />
              </Scrollbar>
            </div>
            {location.pathname.indexOf("/visit") !== -1 && (
              <Button variant="yellow" onClick={() => navigate(-1)}>
                Предыдущие обращения
              </Button>
            )}
            {location.pathname.indexOf("/visit") == -1 && (
              <Button variant="yellow" onClick={() => navigate("/")}>
                Новый пациент
              </Button>
            )}
          </>
        ) : (
          <div className="user-anamnez-block">
            <Scrollbar>
              <LeftChats />
            </Scrollbar>
          </div>
        )}
      </div>
    </>
  );
};
