import "./style.scss";
import "moment/locale/ru";

import {Field, Form, Formik} from "formik";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import CalendarIcon from "../../assets/images/calendar-ico.svg";
import PasswordIcon from "../../assets/images/eye-ico.svg";
import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {hospitalRoutine} from "../../redux/routines/hospitalRoutine";
import {userRegisterRoutine} from "../../redux/routines/userRoutine";
import {useTogglePasswordVisibility} from "../../utils/useTogglePasswordVisibility";
import {registerSchema} from "./schema";

moment.locale("ru");

type Props = {};

const gender = [
  {value: "male", label: "Мужской"},
  {value: "female", label: "Женский"},
];

export const RegisterPage = (props: Props) => {
  const dispatch = useDispatch();
  const {passwordVisibility, handlePasswordVisibility} = useTogglePasswordVisibility();

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");

  const userRegister = useSelector((state: any) => state.user.userRegister);

  const hospitals = useSelector((state: any) => state.hospital.hospitals);
  const [hospitalsSelect, setHospitalsSelect] = useState([]);

  const customInputRef = useRef<HTMLInputElement>();
  const [dateRepeatOpen, setDateRepeatOpen] = useState(false);

  const onClose = () => showAlert(false);

  useEffect(() => {
    dispatch(hospitalRoutine());
  }, []);

  useEffect(() => {
    if (hospitals?.data?.items) {
      let hospitalsTemp = [];
      hospitals?.data?.items.map((item) => {
        if (item.status == "active") hospitalsTemp.push({value: item.id, label: item.name});
      });

      setHospitalsSelect(hospitalsTemp);
    }
  }, [hospitals]);

  useEffect(() => {
    if (userRegister.isSuccess) {
      setAlertTitle("Успех");
      setAlertText("Ваша заявка отправлена на проверку администратором");
      showAlert(true);
    }
    if (userRegister.isFailure) {
      setAlertTitle("Ошибка");
      setAlertText(userRegister.errorMessage);
      showAlert(true);
    }
  }, [userRegister]);

  return (
    <div className="container" id="RegisterPage">
      <h1>Регистрация аккаунта</h1>

      <Formik
        initialValues={{
          lastname: "",
          name: "",
          secondname: "",
          birthday: "",
          gender: "",
          hospital: "",
          agree: false,
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={registerSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          let send = {...values};
          send["role"] = "doctor";
          send.birthday = moment(send.birthday).format("DD.MM.YYYY");
          dispatch(userRegisterRoutine(send));
        }}>
        {({values, errors, touched, setFieldValue}) => (
          <Form className="register-form">
            <div className="column">
              <Field id="lastname" name="lastname" placeholder="Фамилия" component={Input} />
              <Field id="name" name="name" placeholder="Имя" component={Input} />
              <Field id="secondname" name="secondname" placeholder="Отчество" component={Input} />
              <Field id="gender" name="gender" placeholder="Ваш пол" type="select" value={values.gender} component={Input}>
                {gender.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Field>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
                <DatePicker
                  open={dateRepeatOpen}
                  onOpen={() => setDateRepeatOpen(true)}
                  onClose={() => setDateRepeatOpen(false)}
                  value={values.birthday ? moment(values.birthday) : moment()}
                  PopperProps={{anchorEl: customInputRef.current}}
                  onChange={(newValue) => {
                    setFieldValue("birthday", moment(newValue).toDate());
                    if (customInputRef?.current) customInputRef?.current.blur();
                  }}
                  renderInput={(params) => (
                    <div className="form-control-row">
                      <input
                        ref={customInputRef}
                        name="repeat"
                        type="text"
                        value={values.birthday != "" ? moment(values.birthday).format("DD.MM.YYYY") : values.birthday}
                        placeholder="Дата рождения"
                        className="form-control"
                        autoComplete="new-password"
                        onClick={(e) => {
                          e.preventDefault();
                          setDateRepeatOpen(true);
                        }}
                        onChange={(newValue) => {
                          setFieldValue("birthday", newValue);
                          if (customInputRef?.current) customInputRef?.current.blur();
                        }}
                      />
                      <div className="form-control-icon">
                        <img src={CalendarIcon} alt="" />
                      </div>
                    </div>
                  )}
                  dayOfWeekFormatter={(day) => `${day}.`}
                />
              </LocalizationProvider>
              <Field
                name="agree"
                type="checkbox"
                component={Input}
                inputProps={{
                  item: {
                    name: <a href="/policy">Даю согласие на обработку персональных данных</a>,
                  },
                }}></Field>
            </div>
            <div className="column">
              <Field id="hospital" name="hospital" type="select" value={values.hospital} placeholder="Поликлиника" component={Input}>
                {hospitalsSelect.map((hospital) => (
                  <MenuItem key={hospital.value} value={hospital.value}>
                    {hospital.label}
                  </MenuItem>
                ))}
              </Field>
              <Field id="position" name="position" placeholder="Должность" component={Input} />
              <Field id="email" name="email" placeholder="Ваш email" component={Input} />
              <Field
                id="password"
                name="password"
                type={passwordVisibility ? "password" : "text"}
                placeholder="Пароль"
                component={Input}
                inputProps={{
                  rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} />,
                }}
              />
              <Field
                id="confirmPassword"
                name="confirmPassword"
                type={passwordVisibility ? "password" : "text"}
                placeholder="Подтверждение пароля"
                component={Input}
                inputProps={{
                  rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} />,
                }}
              />
              <Button type="submit">Зарегистрироваться</Button>
            </div>

            {Object.values(errors).length > 0 && (
              <div className="form-errors">
                {Object.values(errors).map((msg, index) => (
                  <p key={`err_${index}`}>{msg}</p>
                ))}
              </div>
            )}
          </Form>
        )}
      </Formik>
      <Dialog open={alert} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{sx: {overflowY: "unset"}}}>
        <div style={{overflowY: "unset"}}>
          <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{alertText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton
              color="primary"
              sx={{
                position: "absolute",
                right: "-20px",
                top: "-20px",
                backgroundColor: "lightgray",
                color: "gray",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
