import classNames from "classnames";
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import BackIcon from "../../assets/images/back-ico.svg";
import SendIco from "../../assets/images/send-ico.png";
import {chatReadRoutine, chatSendRoutine} from "../../redux/routines/chatRoutine";

interface Message {
  id: number;
  message: string;
  userId: number;
}

type Props = {
  visitId: number;
  messages: Message[];
  goBack: () => void;
};

export const Chat = ({visitId, messages, goBack}: Props) => {
  const dispatch = useDispatch();

  const chatInputRef = useRef<HTMLInputElement>(null);
  const chatMessRef = useRef(null);

  const user = useSelector((state: any) => state.user.user);

  useEffect(() => {
    dispatch(chatReadRoutine({visitId}));
    chatMessRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (chatMessRef?.current) {
      chatMessRef?.current.addEventListener("DOMNodeInserted", (event) => {
        const {currentTarget: target} = event;
        target.scroll({top: target.scrollHeight, behavior: "smooth"});
      });
      chatMessRef.current.scroll({
        top: chatMessRef.current.scrollHeight,
      });
    }
  }, [chatMessRef?.current]);

  const sendNewMessage = () => {
    let message = chatInputRef.current.value;
    if (message !== "") {
      dispatch(chatSendRoutine({visitId, message}));
      chatInputRef.current.value = "";
    }
  };
  return (
    <div className="chat-block">
      <h2 onClick={goBack}>
        <img src={BackIcon} /> Чат с пациентом
      </h2>
      <div className="chat-container">
        <div className="chat-messages" ref={chatMessRef}>
          {messages.map((item) => (
            <p
              key={`mess_${item.id}`}
              className={classNames("message", {
                my: item.userId == user?.data?.id,
              })}>
              {item.message}
            </p>
          ))}
        </div>
        <div className="chat-input-inner">
          <div className="chat-input">
            <input
              ref={chatInputRef}
              type="text"
              name="message"
              placeholder="Введите сообщение"
              onKeyDown={(event) => {
                if (event.key == "Enter") {
                  event.preventDefault();
                  sendNewMessage();
                }
              }}
            />
            <button
              type="button"
              onClick={() => {
                sendNewMessage();
              }}>
              <img src={SendIco} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
