import { combineReducers } from "redux";

import { createPersistedReducer } from "../../utils/createPersistedReducer";
import { createReducer } from "../../utils/createReducer";
import { hospitalRoutine } from "../routines/hospitalRoutine";

const persistConfig = {
  key: "hospital"
};

export default createPersistedReducer(
  combineReducers({
    hospitals: createReducer(hospitalRoutine, "hospitals", true)
  }),
  persistConfig
);
