import './style.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';

import { ANAMNEZ_HEALTH } from '../../config/config';
import { anamnezRoutine } from '../../redux/routines/anamnezRoutine';
import { chatRoutine } from '../../redux/routines/chatRoutine';
import { visitsRoutine } from '../../redux/routines/visitsRoutine';
import { NoRequests } from '../Home/NoRequests';
import { VisitItem } from '../VisitDetail/VisitItem';

type Props = {};

export const PatientDetailPage = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const patient = params.userId;

  const user = useSelector((state: any) => state.user.user);
  const visits = useSelector((state: any) => state.visits.visits);
  const anamnez = useSelector((state: any) => state.anamnez.anamnez);

  const m = useSelector((state: any) => state.chats.chats);
  const chat = useSelector((state: any) => state.chats.chatSend);

  const [patientVisits, setPatientVisits] = useState({
    active: [],
    finished: [],
  });

  useEffect(() => {
    dispatch(anamnezRoutine({ id: ANAMNEZ_HEALTH }));
    dispatch(visitsRoutine({ userId: patient }));
    dispatch(chatRoutine());
  }, []);

  useEffect(() => {
    if (visits.isSuccess && visits?.data?.items) {
      let tempVisits = { active: [], finished: [] };
      _.each(visits?.data?.items, (visit) => {
        const { status, id } = visit;
        if (m?.data?.items) {
          let c = m?.data?.items.filter(
            (item) =>
              item.userId !== user?.data?.id &&
              item.status == "new" &&
              item.visitId == id
          );
          visit.chats = c.length;
        }

        if (status == "closed") tempVisits.finished.push(visit);
        else tempVisits.active.push(visit);
      });
      setPatientVisits(tempVisits);
    }
  }, [visits, m]);

  useEffect(() => {
    if (chat.isSuccess && chat.data?.chat) {
      let c = chat?.data?.chat;
      let tempVisits = { active: [], finished: [] };
      patientVisits.active.forEach((visit) => {
        if (visit.id == c.visitId)
          visit.chats = visit.chats ? visit.chats + 1 : 1;
        tempVisits.active.push(visit);
      });
      patientVisits.finished.forEach((visit) => {
        if (visit.id == c.visitId)
          visit.chats = visit.chats ? visit.chats + 1 : 1;
        tempVisits.finished.push(visit);
      });

      setPatientVisits(tempVisits);
    }
  }, [chat]);

  return (
    <div className="patient-detail">
      <h2>Обращения</h2>
      <div className="requests-block">
        <div className="requests requests-active">
          {visits.isSuccess && visits?.data?.items.length > 0 && (
            <div className="visit-list">
              <Scrollbar maximalThumbYSize={100}>
                {patientVisits.active.map((visit) => (
                  <VisitItem
                    key={`visit_${visit.id}`}
                    patient={patient}
                    item={visit}
                    anamnez={anamnez}
                  />
                ))}
              </Scrollbar>
            </div>
          )}
          {visits.isSuccess && visits?.data?.items.length === 0 && (
            <NoRequests />
          )}
        </div>
        <div className="requests requests-nonactive">
          <div className="visit-list">
            <Scrollbar maximalThumbYSize={100}>
              {patientVisits.finished.map((visit) => (
                <VisitItem
                  key={`visit_${visit.id}`}
                  patient={patient}
                  item={visit}
                  anamnez={anamnez}
                />
              ))}
            </Scrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};
