import React from "react";
import Empty from "../../assets/images/empty.svg";

export const NoRequests = () => {
	return (
		<div className="no-requests">
			<div className="no-requests-image">
				<img src={Empty} style={{ width: 104, height: 104 }} />
				<div className="no-requests-badge">
					<p className="no-requests-badgetext">0</p>
				</div>
			</div>
			<p className="no-requests-text">У вас пока нет обращений</p>
		</div>
	);
};
