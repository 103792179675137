import {all} from "redux-saga/effects";

import anamnezSaga from "./anamnezSaga";
import chatSaga from "./chatSaga";
import externalSaga from "./externalSaga";
import hospitalSaga from "./hospitalSaga";
import userSaga from "./userSaga";
import visitsSaga from "./visitsSaga";

export default function* rootSaga() {
  yield all([userSaga(), hospitalSaga(), anamnezSaga(), visitsSaga(), chatSaga(), externalSaga()]);
}
