import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {PatientLeft} from "../PatientLeft";

type Props = {};

export const LeftChats = (props: Props) => {
  const chats = useSelector((state: any) => state.chats.chats);
  const chatSended = useSelector((state: any) => state.chats.chatSend);
  const user = useSelector((state: any) => state.user.user);
  const [newMessages, setNewMessages] = useState([]);

  useEffect(() => {
    if (chats.isSuccess && chats?.data?.items) {
      let c = chats?.data?.items.filter((item) => item.userId !== user?.data?.id && item.status == "new");
      let c2 = {};
      c.forEach((item) => {
        if (!c2[item.userId]) c2[item.userId] = {userId: item.userId, user: item.user, chats: 1};
        else c2[item.userId].chats++;
      });
      setNewMessages(Object.values(c2));
    }
  }, [chats]);

  useEffect(() => {
    if (chatSended.isSuccess && chatSended?.data?.chat) {
      let c = chatSended?.data?.chat;
      let c2 = {};
      newMessages.forEach((item) => {
        if (!c2[item.userId])
          c2[item.userId] = {
            userId: item.userId,
            user: item.user,
            chats: item.chats,
          };
        else c2[item.userId].chats++;
      });
      if (c2[c.userId]) c2[c.userId].chats++;
      else
        c2[c.userId] = {
          userId: c.userId,
          user: c.user,
          chats: 1,
        };

      setNewMessages(Object.values(c2));
    }
  }, [chatSended]);

  return (
    <>
      {newMessages.map((item) => (
        <PatientLeft patient={item.userId} user={item.user} chats={item.chats} />
      ))}
    </>
  );
};
