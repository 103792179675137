import { combineReducers } from 'redux';

import { createReducer } from '../../utils/createReducer';
import {
    externalGorloRoutine, externalHripyRoutine, externalPolisRoutine
} from '../routines/externalRoutine';

export default combineReducers({
  polis: createReducer(externalPolisRoutine, "polis"),
  gorlo: createReducer(externalGorloRoutine, "gorlo"),
  hripy: createReducer(externalHripyRoutine, "hripy"),
});
