import {eventChannel} from "redux-saga";
import {call, put, select, take, takeLatest} from "redux-saga/effects";
import {io, Socket} from "socket.io-client";

import {config} from "../../config/config";
import {createSaga} from "../../utils/createSaga";
import {IRemoteData} from "../../utils/RemoteData";
import {chatReadRoutine, chatRoutine, chatSendRoutine} from "../routines/chatRoutine";

function createSocket(): Socket {
  const socket = io(config.domen, {autoConnect: false});
  return socket;
}

function subscribeToUpdates(socket: Socket, method: string) {
  return eventChannel((emit) => {
    function unsubscribe() {
      socket.off(method);
    }

    socket.on(method, (update) => {
      emit(update);
    });
    return unsubscribe;
  });
}

const createSocketSaga = (socket, routine, method) => {
  return function* createCallbackSaga() {
    try {
      const socketChannel = yield call(subscribeToUpdates, socket, method);

      try {
        while (true) {
          const update = yield take(socketChannel);
          yield put(routine.success(update));
        }
      } finally {
        socketChannel.close();
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const getAuth = (state: any) => state.user.auth;

const startSocket = (socket: Socket) => {
  return function* startCallbackSaga() {
    const auth: IRemoteData<any> = yield select(getAuth);

    if (socket.disconnected || !socket.connected) {
      socket.auth = {accessToken: auth?.data?.accessToken};
      socket.on("disconect", (e) => {
        console.log("error", e);
        const reconnect = () => {
          if (socket.disconnected) {
            socket.connect();
          } else {
            setTimeout(() => reconnect(), 1000);
          }
        };

        reconnect();
      });
      socket.on("connect_error", (e) => {
        console.log("error", e);
      });

      const r = socket.connect();
    }
  };
};

function* watchUpdates() {
  const socket: Socket = yield call(createSocket);

  yield takeLatest(chatRoutine.TRIGGER, startSocket(socket));

  yield takeLatest(chatRoutine.TRIGGER, createSocketSaga(socket, chatSendRoutine, "newMessages"));
}

export default function* chatSaga() {
  yield createSaga(chatRoutine, "GET", "api/messages");
  yield createSaga(chatSendRoutine, "POST", "api/messages");
  yield createSaga(chatReadRoutine, "POST", "api/messages/read");

  yield call(watchUpdates);
}
