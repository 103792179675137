import * as yup from 'yup';

export const profileSchema = yup.object().shape({
  lastname: yup.string().required("Заполните фамилию"),
  name: yup.string().required("Заполните имя"),
  secondname: yup.string().required("Заполните отчество"),

  hospital: yup.string().required("Выберите полклинику"),
  email: yup
    .string()
    .email("Введите корректный email")
    .required("Заполните email"),
});

export const passwordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Введите корректный email")
    .required("Заполните email"),
  password: yup.string().required("Заполните пароль"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
});
