import "./style.scss";

import classnames from "classnames";
import {Field, Form, Formik} from "formik";
import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem} from "@mui/material";

import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {ANAMNEZ_HEALTH, ANAMNEZ_LIFE} from "../../config/config";
import {anamnezRoutine, userAnamnezRoutine} from "../../redux/routines/anamnezRoutine";
import {userLogoutRoutine} from "../../redux/routines/userRoutine";
import {visitsAddRoutine} from "../../redux/routines/visitsRoutine";
import {AnamnezItem} from "./AnamnezItem";

type Props = {};

interface mainBlockI {
  [key: string]: any;
}

export const AnamnezDetailPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();

  const anamnez = useSelector((state: any) => state.anamnez.anamnez);
  const userAnamnezSave = useSelector((state: any) => state.anamnez.userAnamnez);

  const [mainBlock, setMainBlock] = useState<mainBlockI>({});
  const [userChoice, setUserChoice] = useState({});
  const [title, setTitle] = useState("");

  const [pages, setPages] = useState(1);
  const [curPage, setCurPage] = useState(0);

  const [pagesBlocks, setPagesBlocks] = useState([]);
  const [showAnamnezRight, setShowAnamnezRight] = useState(false);

  const [showResult, setShowResult] = useState(false);

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");
  const onClose = () => showAlert(false);

  const scrollRef = useRef(null);

  let anamnezType = params.type;
  let anamnezId;

  if (anamnezType === "health") anamnezId = ANAMNEZ_HEALTH;
  else anamnezId = ANAMNEZ_LIFE;

  useEffect(() => {
    dispatch(anamnezRoutine({id: anamnezId}));
    return () => {
      dispatch(userAnamnezRoutine.fulfill());
    };
  }, []);

  useEffect(() => {
    if (anamnez?.data?.items) {
      const data = anamnez?.data?.items;
      if (data.length && data[0]) {
        setPagesBlocks([data[0]]);
        setMainBlock(data[0]);
        _.each(data[0].children, (item) => {
          const key = `code_${item.id}`;
          setUserChoice({...userChoice, [key]: false});
        });
      }
    }
  }, [anamnez]);

  useEffect(() => {
    if (userAnamnezSave.isSuccess) {
      if (mainBlock.id === ANAMNEZ_HEALTH) {
        dispatch(visitsAddRoutine({anamnezId: userAnamnezSave?.data?.id}));

        setAlertTitle("Спасибо!<br>Ваше обращение принято.");
        setAlertText("Оно будет обработано в ближайшее время.<br>Выйти из аккаунта или остаться?");
        showAlert(true);
      } else {
      }
    }
  }, [userAnamnezSave]);

  const handleSendAnamnez = () => {
    dispatch(userAnamnezRoutine({anamnezId: mainBlock.id, values: userChoice}));
  };
  const handleBack = () => {
    navigate("/");
  };
  const handleExit = () => {
    dispatch(userLogoutRoutine());
  };
  const handleRepeat = () => {
    setShowResult(false);
    setShowAnamnezRight(false);
    let uChoiseTemp = {};
    setUserChoice({});
    setPagesBlocks([mainBlock]);
    _.each(mainBlock.children, (item) => {
      const key = `code_${item.id}`;
      uChoiseTemp = {...uChoiseTemp, [key]: false};
    });
    setUserChoice(uChoiseTemp);
  };

  const handleStep = (step) => {
    if (step == 1) {
      setShowAnamnezRight(true);
    } else if (step == -1) {
      setShowAnamnezRight(false);
    }
  };

  const handleNext = (values, index) => {
    let uChoiseTemp = {...userChoice, ...values};
    setUserChoice(uChoiseTemp);
    const blocks = [];
    if (index == 0) {
      _.each(mainBlock.children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (values[key] && item.type != "input" && item.type != "input2") {
          // if (item?.cond?.showChild != false) {
          // 	_.each(item.children, (item2) => {
          // 		const key2 = `code_${item2.id}`;
          // 		console.log("key2", key2);
          // 		//level 2
          // 		console.log(values[key2], item2.type, item2.children);
          // 		if (values[key2] && item2.type == "checkbox" && item2.children && item2.children.length) {
          // 			console.log("level 2", item2);
          // 			item2.parentName = item.name;
          // 			blocks.push(item2);
          // 		}
          // 	});
          // }
          // if (item?.cond?.showChild == false) {
          // 	blocks.push(item);
          // }
          blocks.push(item);
        }
      });
      setCurPage(0);
      setPagesBlocks([pagesBlocks[0], ...blocks]);
    } else if (1 == index) {
      setShowResult(true);
    }
  };

  const renderQuestion = (item, key, index, depth, values, handleChange, handleBlur, setFieldValue) => {
    let innerStyle = {};
    if (depth == 1)
      innerStyle = {
        padding: "23px 27px",
        backgroundColor: "#67B3E2",
        borderRadius: 5,
        marginBottom: 30,
      };
    if (depth >= 2)
      innerStyle = {
        padding: "23px 27px",
        backgroundColor: "rgba(255,255,255,0.3)",
        borderRadius: 5,
        marginTop: 23,
      };
    return (
      <div key={key} style={innerStyle}>
        {item.children.map((item2, itemIndex) => {
          const key = `code_${item2.id}`;
          if (item2.type == "input") {
            return <Field key={key} name={key} value={values && values[key] ? values[key] : ""} type="text" placeholder={item2.name} component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {marginBottom: 16}}></Field>;
          }
          if (item2.type == "checkbox") {
            return <Field key={key} name={key} type="checkbox" component={Input} inputProps={{item: item2}} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}></Field>;
          } else if (item2.type == "selectbox") {
            return (
              <div
                key={key}
                style={{
                  marginTop: 0,
                  marginBottom: itemIndex == item.children.length - 1 ? 0 : 23,
                }}>
                <Field name={key} type="checkbox" component={Input} inputProps={{item: item2}} style={{marginBottom: 0}}></Field>
                {values[key] && item2.children?.length > 0 && renderQuestion(item2, key, index, depth + 1, values, handleChange, handleBlur, setFieldValue)}
              </div>
            );
          } else if (item2.type == "s-checkbox") {
            return (
              <div key={key} style={{marginTop: 0}}>
                <Field name={key} type="checkbox" component={Input} inputProps={{item: item2}} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}></Field>
                {values[key] && item2.children?.length > 0 && renderQuestion(item2, key, index, 3, values, handleChange, handleBlur, setFieldValue)}
              </div>
            );
          } else if (item2.type == "select") {
            const options = [];
            _.each(item2.children, (option) => {
              options.push({value: option.name, label: option.name});
            });
            return (
              options.length > 0 && (
                <div
                  key={key}
                  style={{
                    marginTop: 16,
                  }}>
                  <Field name={key} type="select" value={values && values[key] ? values[key] : ""} placeholder="выбрать" component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}>
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              )
            );
          } else if (item2.type == "s-block") {
            return (
              <div
                key={key}
                style={{
                  marginTop: 0,
                  marginBottom: itemIndex == item.children.length - 1 ? 0 : 23,
                }}>
                <p style={{flex: 1, marginBottom: 0}}>{item2.name}:</p>
                {item2.children?.length > 0 &&
                  renderQuestion(
                    item2,
                    key,
                    index,
                    depth + 1,
                    values,
                    handleChange,
                    handleBlur,

                    setFieldValue
                  )}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderBlock = (condition, index) => {
    return (
      <div key={`block-${index}`} className="anamnez-block-inner">
        <Formik
          initialValues={userChoice}
          onSubmit={(values) => {
            handleNext(values, index);
          }}>
          {({values, errors, handleChange, handleBlur, setFieldValue}) => (
            <Form
              onChange={(e) => {
                if (index == 0) {
                  let target = e.target as HTMLButtonElement;
                  if (target.type == "checkbox") {
                    let target = e.target as HTMLInputElement;
                    values[target.name] = target.checked;
                  } else {
                    values[target.name] = target.value;
                  }
                  handleNext(values, index);
                }
              }}>
              <Scrollbar permanentTrackY={index == 0}>
                {pagesBlocks.map(
                  (pageBlock, index) =>
                    condition(index) && (
                      <div key={`block-${index}`}>
                        {index !== 0 && <p>{pageBlock.name}</p>}
                        <div
                          style={
                            index >= 1
                              ? {
                                  padding: "23px 27px",
                                  backgroundColor: "rgba(255,255,255,0.3)",
                                  borderRadius: 5,
                                  marginBottom: 23,
                                }
                              : {}
                          }>
                          {pageBlock.children.map((item, itemIndex) => {
                            const key = `code_${item.id}`;

                            let options = [];
                            if (item.type == "select")
                              _.each(item.children, (option) => {
                                options.push({
                                  value: option.name,
                                  label: option.name,
                                });
                              });

                            if (index > 0 && item.type == "input") item.type = "input2";

                            return (
                              <div key={key}>
                                {item.type == "checkbox" && (
                                  <>
                                    <Field name={key} type="checkbox" component={Input} inputProps={{item}}></Field>

                                    {values[key] &&
                                      index != 0 &&
                                      item.children?.length > 0 &&
                                      item?.cond?.showChild != false &&
                                      renderQuestion(
                                        item,
                                        key,
                                        index,
                                        1,
                                        values,
                                        handleChange,
                                        handleBlur,

                                        setFieldValue
                                      )}
                                  </>
                                )}
                                {item.type == "input" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <label
                                      style={{
                                        flex: 1,
                                        fontSize: 16,
                                        lineHeight: "19px",
                                        color: "#FFFFFF",
                                      }}>
                                      {item.name}:
                                    </label>
                                    <Field name={key} type="text" value={values && values[key] ? values[key] : ""} component={Input} style={{width: 80, marginLeft: 20}}></Field>
                                  </div>
                                )}
                                {item.type == "input2" && (
                                  <div>
                                    <Field name={key} type="text" value={values && values[key] ? values[key] : ""} placeholder={item.name} component={Input} style={{marginBottom: 16}}></Field>
                                  </div>
                                )}
                                {item.type == "select" && (
                                  <>
                                    {options.length > 0 && (
                                      <div
                                        key={key}
                                        style={{
                                          marginTop: 16,
                                        }}>
                                        <Field name={key} type="select" value={values && values[key] ? values[key] : ""} placeholder="выбрать" component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}>
                                          {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Field>
                                      </div>
                                    )}
                                  </>
                                )}
                                {item.type == "selectbox" && (
                                  <>
                                    <Field name={key} type="checkbox" component={Input} inputProps={{item: item}} style={{marginBottom: 0}}></Field>
                                    {values[key] && item.children?.length > 0 && renderQuestion(item, key, index, 1, values, handleChange, handleBlur, setFieldValue)}
                                  </>
                                )}
                                {item.type == "s-block" && (
                                  <>
                                    <p style={{flex: 1}}>{item.name}:</p>
                                    {item.children?.length > 0 &&
                                      renderQuestion(
                                        item,
                                        key,
                                        index,
                                        1,
                                        values,
                                        handleChange,
                                        handleBlur,

                                        setFieldValue
                                      )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                )}
              </Scrollbar>
              <div className="btn-row">
                {index === 0 && !showAnamnezRight && <Button onClick={() => handleStep(1)}>Далее</Button>}
                {showAnamnezRight && index !== 0 && (
                  <>
                    <Button onClick={() => handleStep(-1)}>Назад</Button>
                    <Button variant="yellow" type="submit">
                      К врачу
                    </Button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="anamnez-detail">
      {!showResult && (
        <>
          <h1>{mainBlock.name}</h1>
          <div className="anamnez-list">
            <div
              className={classnames("anamnez-block", "anamnez-left", {
                hidden: showAnamnezRight,
              })}>
              {renderBlock((index) => index == 0, 0)}
            </div>
            <div
              className={classnames("anamnez-block", "anamnez-right", {
                hidden: !showAnamnezRight,
              })}>
              <div className="scroller" ref={scrollRef}>
                <div style={{display: "flex", height: "100%"}}>{renderBlock((index) => index > 0, 1)}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {showResult && (
        <>
          <h1>Результат опроса</h1>
          <div className="anamnez-result">
            <Scrollbar>
              <div
                style={{
                  padding: "0 36px 23px",
                  flex: 1,
                }}>
                {pagesBlocks.map((pageBlock, index) => {
                  const key = `code_${pageBlock.id}`;
                  if (userChoice[key]) {
                  }
                  const text = (item, itemIndex = 1) => {
                    let returnText = "";
                    const key = `code_${item.id}`;
                    if (item.children.length > 0) {
                      _.each(item.children, (item2, index2) => {
                        const key2 = `code_${item2.id}`;
                        //level 2
                        if (item2.type == "s-block") {
                          returnText += text(item2);
                        } else if (userChoice[key2]) {
                          if (item2.children.length > 0) {
                            if (item2.type == "select") {
                              returnText += `${item2.name}: ${userChoice[key2]}<br />`;
                            } else {
                              returnText += `${item2.type != "selectbox" && itemIndex != 1 ? "<br />" : ""}${item2.name}:${item2.type == "selectbox" ? " " : "<br />"}` + text(item2, itemIndex + 1);
                            }
                          } else {
                            if (item2.type != "checkbox") {
                              returnText += `${item2.name}: ${userChoice[key2]}${parseInt(index2) != item.children.length - 1 ? "<br />" : ""}`;
                            } else {
                              returnText += `${item2.name} `;
                            }
                          }
                        }
                      });
                    } else {
                      if (item.type != "checkbox") {
                        returnText += `${item.name}: ${userChoice[key]}<br />`;
                      } else {
                        returnText += `${item.name} `;
                      }
                    }
                    return returnText;
                  };

                  if (userChoice[key]) {
                    const resultItem = {
                      title: pageBlock.name,
                      text: text(pageBlock),
                    };
                    return (
                      <AnamnezItem
                        item={resultItem}
                        index={index}
                        onSelect={(index) => {
                          setCurPage(index);
                        }}
                      />
                    );
                  }
                })}
                <div className="btn-row">
                  <Button onClick={() => handleRepeat()}>Пройти заново</Button>
                  <Button variant="yellow" onClick={() => handleSendAnamnez()}>
                    К врачу
                  </Button>
                </div>
              </div>
            </Scrollbar>
          </div>
        </>
      )}
      <Dialog
        open={alert}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            padding: "34px 70px 50px",
            overflowY: "unset",
            background: "linear-gradient(180deg, #0087DB 0%, #0776BB 100%)",
            borderRadius: "5px",
          },
        }}>
        <div style={{overflowY: "unset"}}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{color: "#fff", textAlign: "center"}}
            dangerouslySetInnerHTML={{
              __html: alertTitle,
            }}></DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{color: "#fff", textAlign: "center"}}
              dangerouslySetInnerHTML={{
                __html: alertText,
              }}></DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button variant="yellow" style={{padding: "0 50px"}} onClick={handleExit}>
              Выйти
            </Button>
            <Button style={{padding: "0 50px"}} onClick={handleBack}>
              Остаться
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
