import './style.scss';

import React, { CSSProperties } from 'react';

import classNames from 'classnames';

import EditIco from '../../assets/images/edit-ico.svg';

export type Item = {
  title: string;
  text: string;
};

type Props = {
  item: Item;
  index: number;
  onSelect?: (index: number) => void;
  size?: "small";
  style?: CSSProperties;
};

export const AnamnezItem = ({ item, index, onSelect, size, style }: Props) => {
  const { title, text } = item;
  return (
    <div
      className={classNames("anamnez-card", {
        "anamnez-cardSmall": size === "small",
      })}
      style={style}
    >
      {title && (
        <div
          className={classNames("anamnez-titleRow", {
            "anamnez-titleRowSmall": size === "small",
          })}
        >
          <p
            className={classNames("anamnez-cardText", "anamnez-cardTitle", {
              "anamnez-cardTitleSmall": size === "small",
            })}
          >
            {title}
          </p>
          {onSelect && (
            <a
              onClick={() => {
                onSelect(index);
              }}
            >
              <img src={EditIco} style={{ width: 13, height: 14 }} />
            </a>
          )}
        </div>
      )}
      <p
        className={classNames("anamnez-cardText", {
          "anamnez-cardTextSmall": size == "small",
        })}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></p>
    </div>
  );
};
