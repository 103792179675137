import Axios from "axios";

import {config} from "../config/config";

const Api = {
  request: async (url: string, method: "GET" | "POST" | "DELETE", payload: any, auth: any, options?: any) => {
    Axios.defaults.baseURL = config.domen;

    if (options && options.domen) {
      Axios.defaults.baseURL = options.domen;
    }
    Axios.defaults.headers.common["Content-Type"] = "application/json";

    if (auth?.data?.accessToken) {
      Axios.defaults.headers.common.Authorization = `Bearer ${auth?.data?.accessToken}`;
    }

    if (options?.withFile) {
      Axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      let formData = new FormData();
      const buildFormData = (formData, data, parentKey = "") => {
        if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
          Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
          });
        } else {
          const value = data == null ? "" : data;

          formData.append(parentKey, value);
        }
      };

      buildFormData(formData, payload);
      payload = formData;
    }

    Axios.defaults.headers.common["Accept"] = "application/json";
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
    //console.log(url, method, payload);
    try {
      const result = await Axios.request({
        url,
        method,
        [dataOrParams]: payload,
      });

      let json = result.data;
      return json;
    } catch (e: any) {
      console.log(e?.response);
      if (e?.response?.data) {
        return e.response.data;
      }
      return {
        error: "fetch_failed_500",
        success: false,
        error_more: "Ошибка сервера. Попробуйте позже",
      };
    }
  },
};

export default Api;
