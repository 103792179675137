import {combineReducers} from "redux";

import anamnez from "./anamnezReducer";
import chats from "./chatsReducer";
import external from "./externalReducer";
import hospital from "./hospitalReducer";
import user from "./userReducer";
import visits from "./visitsReducer";

export default combineReducers({
  user,
  hospital,
  anamnez,
  visits,
  chats,
  external,
});
