import "./style.scss";

import {Form, Formik} from "formik";
import {debounce} from "lodash";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Autocomplete, TextField} from "@mui/material";

import {Button} from "../../components/Button";
import {chatRoutine} from "../../redux/routines/chatRoutine";
import {userSearchRoutine} from "../../redux/routines/userRoutine";

type Props = {};

export const HomePage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSearch = useSelector((state: any) => state.user.userSearch);
  const user = useSelector((state: any) => state.user.user);
  const auth = useSelector((state: any) => state.user.auth);

  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  useEffect(() => {
    if (user.isSuccess && user?.data?.status == "active" && auth.isSuccess && auth?.data?.accessToken) {
      dispatch(chatRoutine());
    }
  }, [user, auth]);

  const fetch = React.useMemo(
    () =>
      debounce((request: {input: string}) => {
        dispatch(userSearchRoutine({q: request.input}));
      }, 300),
    []
  );

  useEffect(() => {
    if (inputValue) fetch({input: inputValue});
    else setOptions([]);
  }, [inputValue]);

  useEffect(() => {
    if (userSearch.isSuccess && userSearch?.data?.items) {
      let opt = userSearch?.data?.items.map((item) => ({
        id: item.id,
        label: `${item.lastname} ${item.name} ${item.secondname}`,
      }));
      setOptions(opt);
    }
  }, [userSearch]);

  return (
    <div className="index-block">
      <h2>Кабинет врача</h2>
      {user?.data?.status != "active" ? (
        <div className="alert alert-error">Ваш аккаунт не активен</div>
      ) : (
        <div className="search-block">
          <Formik initialValues={{q: ""}} onSubmit={(values) => {}}>
            {({values, errors, handleChange, handleBlur, setFieldValue}) => (
              <Form>
                <Autocomplete
                  id="search-patient"
                  sx={{width: 300}}
                  getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
                  filterOptions={(x) => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={values.q}
                  noOptionsText="Ничего не найдено"
                  onChange={(event: any, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    if (newValue.id) {
                      navigate(`/patient/${newValue.id}`);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    setFieldValue("q", newInputValue);
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="Введите ID пациента" variant="filled" fullWidth />}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.label}</li>;
                  }}
                />

                <Button variant="yellow" type="submit" style={{width: 150}}>
                  Найти
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};
