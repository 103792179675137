import classnames from "classnames";
import {Field, Form, Formik} from "formik";
import _ from "lodash";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Scrollbar} from "react-scrollbars-custom";
import * as yup from "yup";

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem} from "@mui/material";

import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {ANAMNEZ_HEALTH, ANAMNEZ_LIFE} from "../../config/config";
import {anamnezLifeRoutine, userAnamnezUpdateRoutine} from "../../redux/routines/anamnezRoutine";
import {userLogoutRoutine} from "../../redux/routines/userRoutine";
import {AnamnezItem} from "./AnamnezItem";

type Props = {};
interface mainBlockI {
  [key: string]: any;
}

export const AnamnezEditPage = (props: Props) => {
  const anamnezLife = useSelector((state: any) => state.anamnez.anamnezLife);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  let anamnezIndex = params.index;
  let userAnamnez = params.userAnamnez;
  let patientId = params.userId;

  const anamnez = useSelector((state: any) => state.anamnez.anamnezLife);
  const userAnamnezUpdate = useSelector((state: any) => state.anamnez.userAnamnezUpdate);
  const userAnamnezSave = useSelector((state: any) => state.anamnez.userAnamnez);

  const user = useSelector((state: any) => state.user.userSearch);

  const [mainBlock, setMainBlock] = useState<mainBlockI>({});
  const [userChoice, setUserChoice] = useState({});
  const [title, setTitle] = useState("");

  const [pages, setPages] = useState(1);
  const [curPage, setCurPage] = useState(0);

  const [pagesBlocks, setPagesBlocks] = useState([]);
  const [pagesBlocksError, setPagesBlocksError] = useState<string[]>([]);
  const [showAnamnezRight, setShowAnamnezRight] = useState(false);

  const [showResult, setShowResult] = useState(false);
  const [formErrors, setErrors] = useState({});

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");
  const onClose = () => showAlert(false);

  const scrollRef = useRef(null);

  let anamnezType = params.type;
  let anamnezId = ANAMNEZ_LIFE;

  useEffect(() => {
    dispatch(userAnamnezUpdateRoutine.fulfill());
    return () => {
      dispatch(userAnamnezUpdateRoutine.fulfill());
    };
  }, []);

  useEffect(() => {
    let tempChoice = {};
    if (anamnezLife?.data?.items) {
      const data = anamnezLife?.data?.items;
      if (data.length && data[0]) {
        _.each(data[0].children, (item) => {
          const key = `code_${item.id}`;
          tempChoice = {...tempChoice, [key]: false};
        });
      }

      if (anamnezLife.data.userChoice) {
        tempChoice = {...tempChoice, ...anamnezLife.data.userChoice};
      }
      setUserChoice(tempChoice);

      const blocks = [data[0]];
      _.each(data[0].children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (tempChoice[key]) {
          if (item?.cond?.showChild != false) {
            _.each(item.children, (item2) => {
              const key2 = `code_${item2.id}`;
              //level 2
              if (tempChoice[key2] && item2.type == "checkbox" && item2.children && item2.children.length) {
                item2.parentName = item.name;
                blocks.push(item2);
              }
            });
          }
          if (item?.cond?.showChild == false) {
            blocks.push(item);
          }
        }
      });
      setPagesBlocks(blocks);
      setShowAnamnezRight(true);
    }
  }, [anamnezLife]);

  useEffect(() => {
    if (userAnamnezUpdate.isSuccess) {
      dispatch(anamnezLifeRoutine({id: ANAMNEZ_LIFE, userId: patientId}));
      navigate(`/patient/${patientId}`);
    }
  }, [userAnamnezUpdate]);

  const handleSendAnamnez = (values) => {
    dispatch(userAnamnezUpdateRoutine({userAnamnezId: userAnamnez, values}));
  };
  const handleBack = () => {
    navigate("/");
  };
  const handleExit = () => {
    dispatch(userLogoutRoutine());
  };

  const handleRepeat = () => {
    setErrors({});
    setShowResult(false);
    setShowAnamnezRight(false);
    let uChoiseTemp = {};
    setUserChoice({});
    setPagesBlocks([mainBlock]);
  };

  const handleStep = async (step, values?) => {
    if (step == 1) {
      let res = await handleNext(values, 0);
      if (res !== false) {
        setShowAnamnezRight(true);
      }
    } else if (step == -1) {
      setShowAnamnezRight(false);
    }
  };

  const schema = yup.object().shape({
    code_25: yup.number().required("Заполните количество дней"),
  });

  const handleNext = async (values, index) => {
    let uChoiseTemp = index == 0 ? {...userChoice, ...values} : {...values, ...userChoice};

    let errors = [];
    setUserChoice(uChoiseTemp);
    const blocks = [];
    let selected = 0;
    if (index == 0) {
      _.each(mainBlock.children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (values[key]) {
          selected++;
        }
        if (values[key] && item.type !== "input" && item.type !== "input2" && item.type !== "selectWL") {
          // if (item?.cond?.showChild != false) {
          // 	_.each(item.children, (item2) => {
          // 		const key2 = `code_${item2.id}`;
          // 		console.log("key2", key2);
          // 		//level 2
          // 		console.log(values[key2], item2.type, item2.children);
          // 		if (values[key2] && item2.type == "checkbox" && item2.children && item2.children.length) {
          // 			console.log("level 2", item2);
          // 			item2.parentName = item.name;
          // 			blocks.push(item2);
          // 		}
          // 	});
          // }
          // if (item?.cond?.showChild == false) {
          // 	blocks.push(item);
          // }
          blocks.push(item);
        }
      });
      setCurPage(0);
      setPagesBlocks([pagesBlocks[0], ...blocks]);
      setPagesBlocksError([]);

      if (errors.length) {
        setPagesBlocksError([errors.join("\n")]);
        return false;
      }

      const needSelectedCount = anamnezId == ANAMNEZ_HEALTH ? 2 : 1;
      if (selected < needSelectedCount) {
        const error = ["Отметьте другие признаки"];
        setPagesBlocksError(error);
        return false;
      }
    } else if (1 == index) {
      try {
        let result = await schema.validate(uChoiseTemp, {abortEarly: false});
        setShowResult(true);
      } catch (validationErrors) {
        const allErrors = validationErrors.inner.reduce(
          (errors, currentValidation) =>
            Object.assign(errors, {
              [currentValidation.path]: currentValidation.errors[0], //first error is enough for this demo
            }),
          {}
        );
        setErrors(allErrors);
      }
    }
    return true;
  };

  const renderQuestion = (item, key, index, depth, values, handleChange, handleBlur, setFieldValue) => {
    let innerStyle = {};
    if (depth == 1)
      innerStyle = {
        padding: "23px 27px",
        backgroundColor: "#67B3E2",
        borderRadius: 5,
        marginBottom: 30,
      };
    if (depth >= 2)
      innerStyle = {
        padding: "23px 27px",
        backgroundColor: "rgba(255,255,255,0.3)",
        borderRadius: 5,
        marginTop: 23,
        marginBottom: depth == 2 ? 20 : 0,
      };
    return (
      <div key={key} style={innerStyle}>
        {item.children.map((item2, itemIndex) => {
          const key = `code_${item2.id}`;
          let options = [];
          if ((item2.cond && item2.cond.showDays) || item2.code == "year") {
            item2.type = "selectWL";

            let min = 1,
              max = Number(item2.cond.daysCount);
            if (item2.code == "year") {
              min = parseInt(moment(user.data?.user?.birthday).format("YYYY"));

              max = new Date().getFullYear();
            }

            if (!max) {
              if (values[item2.cond.daysCount]) {
                max = Number(values[item2.cond.daysCount]);
              } else {
                max = 1;
              }
            }
            options = _.range(min, max + 1).map((item) => ({
              value: item,
              label: item,
            }));

            if (item2.code == "year" && values[key] && values[key] < min) options = [{value: parseInt(values[key]), label: values[key]}, ...options];
          }
          if (item2.type == "input") {
            return (
              <>
                <Field key={key} name={key} value={values && values[key] ? values[key] : ""} type="text" placeholder={item2.name} component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {marginBottom: 16}}></Field>
                {formErrors[key] && <p className="error">{formErrors[key]}</p>}
              </>
            );
          }
          if (item2.type == "checkbox" || item2.type == "radio") {
            return <Field key={key} name={key} type="checkbox" component={Input} inputProps={{item: item2, parent: item}} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}></Field>;
          } else if (item2.type == "selectbox" || item2.type == "radiobox") {
            return (
              <div
                key={key}
                style={{
                  marginTop: 0,
                  marginBottom: itemIndex == item.children.length - 1 ? 0 : 23,
                }}>
                <Field name={key} type="checkbox" component={Input} inputProps={{item: item2, parent: item}} style={{marginBottom: 0}}></Field>
                {values[key] && item2.children?.length > 0 && renderQuestion(item2, key, index, depth + 1, values, handleChange, handleBlur, setFieldValue)}
              </div>
            );
          } else if (item2.type == "s-checkbox") {
            return (
              <div key={key} style={{marginTop: 0}}>
                <Field name={key} type="checkbox" component={Input} inputProps={{item: item2}} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}></Field>
                {values[key] && item2.children?.length > 0 && renderQuestion(item2, key, index, 3, values, handleChange, handleBlur, setFieldValue)}
              </div>
            );
          } else if (item2.type == "select") {
            const options = [];
            _.each(item2.children, (option) => {
              options.push({value: option.name, label: option.name});
            });
            return (
              options.length > 0 && (
                <div key={key} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {marginBottom: 16}}>
                  <Field name={key} type="select" value={values && values[key] ? values[key] : ""} placeholder="выбрать" component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}>
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              )
            );
          } else if (item2.type == "selectWL") {
            return (
              options.length > 0 && (
                <div key={key} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {marginBottom: 16}}>
                  <Field name={key} type="select" value={values && values[key] ? values[key] : ""} placeholder="выбрать" component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}>
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              )
            );
          } else if (item2.type == "s-block" || item2.type == "s-block-radio") {
            return (
              <div
                key={key}
                style={{
                  marginTop: 0,
                  marginBottom: itemIndex == item.children.length - 1 ? 0 : 23,
                }}>
                <p style={{flex: 1, marginBottom: 0}}>{item2.name}:</p>
                {item2.children?.length > 0 &&
                  renderQuestion(
                    item2,
                    key,
                    index,
                    depth + 1,
                    values,
                    handleChange,
                    handleBlur,

                    setFieldValue
                  )}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderBlock = (condition, index) => {
    return (
      <div key={`block-${index}`} className="anamnez-block-inner">
        <Formik
          initialValues={userChoice}
          onSubmit={(values) => {
            //handleNext(values, index);
          }}>
          {({values, errors, handleChange, handleBlur, setFieldValue}) => (
            <Form
              onChange={(e) => {
                if (index == 0) {
                  let target = e.target as HTMLButtonElement;
                  if (target.type == "checkbox") {
                    let target = e.target as HTMLInputElement;
                    values[target.name] = target.checked;
                  } else {
                    values[target.name] = target.value;
                  }
                  //handleNext(values, index);
                }
              }}>
              <Scrollbar permanentTrackY={index == 0}>
                {pagesBlocks.map(
                  (pageBlock, index) =>
                    condition(index) && (
                      <div key={`block-${index}`}>
                        {index !== 0 && <p>{pageBlock.name}</p>}
                        <div
                          style={
                            index >= 1
                              ? {
                                  padding: "23px 27px",
                                  backgroundColor: "rgba(255,255,255,0.3)",
                                  borderRadius: 5,
                                  marginBottom: 23,
                                }
                              : {}
                          }>
                          {pageBlock.children.map((item, itemIndex) => {
                            const key = `code_${item.id}`;

                            let options = [];
                            if (item.type == "select")
                              _.each(item.children, (option) => {
                                options.push({
                                  value: option.name,
                                  label: option.name,
                                });
                              });

                            if (index > 0 && item.type == "input") {
                              item.type = "input2";
                            }

                            if ((item.cond && item.cond.showDays) || item.code == "year") {
                              item.type = "selectWL";
                              let min = 1,
                                max = Number(item.cond.daysCount);
                              if (item.code == "year") {
                                min = parseInt(moment(user.data?.birthday).format("YYYY"));
                                max = new Date().getFullYear();
                              }

                              if (!max) {
                                if (userChoice[item.cond.daysCount]) {
                                  max = Number(userChoice[item.cond.daysCount]);
                                } else {
                                  max = 1;
                                }
                              }
                              options = _.range(min, max + 1).map((item) => ({
                                value: item,
                                label: item,
                              }));
                            }

                            return (
                              <div key={key}>
                                {(item.type == "checkbox" || item.type == "radio") && (
                                  <>
                                    <Field name={key} type="checkbox" component={Input} inputProps={{item, parent: pageBlock}}></Field>

                                    {values[key] &&
                                      item.children?.length > 0 &&
                                      item?.cond?.showChild != false &&
                                      renderQuestion(
                                        item,
                                        key,
                                        index,
                                        1,
                                        values,
                                        handleChange,
                                        handleBlur,

                                        setFieldValue
                                      )}
                                  </>
                                )}
                                {item.type == "input" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <label
                                      style={{
                                        flex: 1,
                                        fontSize: 16,
                                        lineHeight: "19px",
                                        color: "#FFFFFF",
                                      }}>
                                      {item.name}:
                                    </label>
                                    <Field name={key} type="text" value={values && values[key] ? values[key] : ""} component={Input} style={{width: 80, marginLeft: 20}}></Field>
                                  </div>
                                )}
                                {item.type == "input2" && (
                                  <div>
                                    <Field name={key} type="text" value={values && values[key] ? values[key] : ""} placeholder={item.name} component={Input} style={itemIndex == pageBlock.children.length - 1 ? {marginBottom: 0} : {}}></Field>
                                    {formErrors[key] && <p className="error">{formErrors[key]}</p>}
                                  </div>
                                )}
                                {item.type == "select" && (
                                  <>
                                    {options.length > 0 && (
                                      <div
                                        key={key}
                                        style={{
                                          marginTop: 16,
                                        }}>
                                        <Field name={key} type="select" value={values && values[key] ? values[key] : ""} placeholder="выбрать" component={Input} style={itemIndex == pageBlock.children.length - 1 ? {marginBottom: 0} : {}}>
                                          {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Field>
                                      </div>
                                    )}
                                  </>
                                )}
                                {item.type == "selectWL" && (
                                  <>
                                    {options.length > 0 && (
                                      <div
                                        key={key}
                                        style={{
                                          marginTop: 16,
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <label
                                          style={{
                                            flex: 1,
                                            fontSize: 16,
                                            lineHeight: "19px",
                                            color: "#FFFFFF",
                                          }}>
                                          {item.name}:
                                        </label>
                                        <Field
                                          name={key}
                                          type="select"
                                          value={values && values[key] ? values[key] : ""}
                                          placeholder="выбрать"
                                          component={Input}
                                          style={itemIndex == pageBlock.children.length - 1 ? {marginBottom: 0} : {}}
                                          inputProps={{
                                            selectStyle: {maxHeight: 300},
                                          }}>
                                          {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Field>
                                      </div>
                                    )}
                                  </>
                                )}
                                {(item.type == "selectbox" || item.type == "radiobox") && (
                                  <>
                                    <Field
                                      name={key}
                                      type="checkbox"
                                      component={Input}
                                      inputProps={{
                                        item: item,
                                        parent: pageBlock,
                                      }}
                                      style={{marginBottom: 16}}></Field>
                                    {values[key] && item.children?.length > 0 && renderQuestion(item, key, index, 2, values, handleChange, handleBlur, setFieldValue)}
                                  </>
                                )}
                                {(item.type == "s-block" || item.type == "s-block-radio") && (
                                  <>
                                    <p style={{flex: 1}}>{item.name}:</p>
                                    {item.children?.length > 0 &&
                                      renderQuestion(
                                        item,
                                        key,
                                        index,
                                        1,
                                        values,
                                        handleChange,
                                        handleBlur,

                                        setFieldValue
                                      )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                )}
              </Scrollbar>
              <div className="btn-row">
                {index === 0 && !showAnamnezRight && (
                  <>
                    <Button onClick={() => handleStep(1, values)}>Далее</Button>
                    {pagesBlocksError[curPage] && (
                      <div
                        style={{
                          backgroundColor: "#f8d7da",
                          padding: "10px 20px",
                          borderRadius: 5,
                        }}>
                        <p style={{color: "#721c24"}}>{pagesBlocksError[curPage]}</p>
                      </div>
                    )}
                  </>
                )}
                {showAnamnezRight && index !== 0 && (
                  <>
                    {/* <Button onClick={() => handleStep(-1)}>Назад</Button> */}
                    <Button variant="yellow" onClick={() => handleSendAnamnez(values)}>
                      Сохранить
                    </Button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="anamnez-detail">
      {!showResult && (
        <>
          <h1>{mainBlock.name}</h1>
          <div className="anamnez-list">
            <div
              className={classnames("anamnez-block", "anamnez-left", {
                hidden: showAnamnezRight,
              })}>
              {pagesBlocks.map((pageBlock, index) => {
                if (index == Number(anamnezIndex)) {
                  const key = `code_${pageBlock.id}`;
                  const text = (item, itemIndex = 1) => {
                    let returnText = "";
                    const key = `code_${item.id}`;
                    if (item.children.length > 0) {
                      let visibleIndex = 0;
                      _.each(item.children, (item2, index2) => {
                        const key2 = `code_${item2.id}`;
                        //level 2
                        if (item2.type == "s-block" || item2.type == "s-block-radio") {
                          const blockText = text(item2);
                          if (blockText) {
                            returnText += `${item2.name}:<br />${blockText}${Number(index2) != item.children.length - 1 ? "<br />" : ""}`;
                          }
                        } else if (userChoice[key2]) {
                          if (item2.children.length > 0) {
                            if (item2.type == "select") {
                              returnText += `${item2.name}: ${userChoice[key2]}<br />`;
                            } else {
                              returnText += `${itemIndex == 2 || (item2.type != "selectbox" && item2.type != "radiobox") ? "<br />" : ""}${item2.name}: ${item2.type == "selectbox" || item2.type == "radiotbox" ? "" : "<br />"}` + text(item2, itemIndex + 1);
                            }
                          } else {
                            if (item2.type != "checkbox" && item2.type != "radio") {
                              returnText += `${item2.name}: ${userChoice[key2]}${Number(index2) != item.children.length - 1 ? "<br />" : "<br />"}`;
                            } else {
                              returnText += `${item2.name}${Number(index2) != item.children.length - 1 && itemIndex != 1 ? `, ` : "<br>"}`;
                            }
                          }
                          if (userChoice[key2]) {
                            visibleIndex++;
                          }
                        }
                      });
                    } else {
                      if (userChoice[key]) {
                        if (item.type != "checkbox") {
                          returnText += `${item.name}: ${userChoice[key]}<br />`;
                        } else {
                          returnText += `${item.name} `;
                        }
                      }
                    }
                    return returnText;
                  };

                  if (index == 0) {
                    const items = pageBlock.children.map((item) => {
                      if (item.cond && item.cond.showMain) {
                        const resultItem = {
                          title: item.type == "checkbox" ? item.name : "",
                          text: text(item),
                        };
                        if (resultItem.text) {
                          return <AnamnezItem key={`code_${item.id}`} item={resultItem} index={index} style={{marginRight: 16}} />;
                        }
                      }
                    });
                    return items;
                  }
                  if (userChoice[key]) {
                    const resultItem = {
                      title: pageBlock.name,
                      text: text(pageBlock),
                    };
                    return <AnamnezItem key={key} item={resultItem} index={index} style={{marginRight: 16}} />;
                  }
                }
              })}
            </div>
            <div
              className={classnames("anamnez-block", "anamnez-right", {
                hidden: !showAnamnezRight,
              })}>
              <div className="scroller" ref={scrollRef}>
                <div style={{display: "flex", height: "100%"}}>{showAnamnezRight && renderBlock((index) => index == anamnezIndex, anamnezIndex)}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {showResult && (
        <>
          <h1>Результат опроса</h1>
          <div className="anamnez-result">
            <Scrollbar>
              <div
                style={{
                  padding: "0 36px 23px",
                  flex: 1,
                }}>
                {pagesBlocks.map((pageBlock, index) => {
                  const key = `code_${pageBlock.id}`;
                  if (userChoice[key]) {
                  }
                  const text = (item, itemIndex = 1) => {
                    let returnText = "";
                    const key = `code_${item.id}`;
                    if (item.children.length > 0) {
                      let visibleIndex = 0;
                      _.each(item.children, (item2, index2) => {
                        const key2 = `code_${item2.id}`;
                        //level 2
                        if (item2.type == "s-block" || item2.type == "s-block-radio") {
                          const blockText = text(item2);
                          if (blockText) {
                            returnText += `${item2.name}:<br />${blockText}${Number(index2) != item.children.length - 1 ? "<br />" : ""}`;
                          }
                        } else if (userChoice[key2]) {
                          if (item2.children.length > 0) {
                            if (item2.type == "select") {
                              returnText += `${item2.name}: ${userChoice[key2]}<br />`;
                            } else {
                              returnText += `${item2.type != "selectbox" && item2.type != "radiobox" && visibleIndex > 0 ? "<br />" : ""}${item2.name}:${item2.type == "selectbox" || item2.type == "radiotbox" ? " " : "<br />"}` + text(item2, itemIndex + 1);
                            }
                          } else {
                            if (item2.type != "checkbox" && item2.type != "radio") {
                              returnText += `${item2.name}: ${userChoice[key2]}${Number(index2) != item.children.length - 1 ? "<br />" : ""}`;
                            } else {
                              returnText += `${item2.name}${Number(index2) != item.children.length - 1 ? ", " : ""}`;
                            }
                          }
                          if (userChoice[key2]) {
                            visibleIndex++;
                          }
                        }
                      });
                    } else {
                      if (userChoice[key]) {
                        if (item.type != "checkbox") {
                          returnText += `${item.name}: ${userChoice[key]}<br />`;
                        } else {
                          returnText += `${item.name} `;
                        }
                      }
                    }
                    return returnText;
                  };

                  if (userChoice[key]) {
                    const resultItem = {
                      title: pageBlock.name,
                      text: text(pageBlock),
                    };
                    return (
                      <AnamnezItem
                        item={resultItem}
                        index={index}
                        onSelect={(index) => {
                          setShowResult(false);
                        }}
                      />
                    );
                  }
                })}
                <div className="btn-row">
                  <Button onClick={() => handleRepeat()}>Пройти заново</Button>
                </div>
              </div>
            </Scrollbar>
          </div>
        </>
      )}
      <Dialog
        open={alert}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            padding: "34px 70px 50px",
            overflowY: "unset",
            background: "linear-gradient(180deg, #0087DB 0%, #0776BB 100%)",
            borderRadius: "5px",
          },
        }}>
        <div style={{overflowY: "unset"}}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{color: "#fff", textAlign: "center"}}
            dangerouslySetInnerHTML={{
              __html: alertTitle,
            }}></DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{color: "#fff", textAlign: "center"}}
              dangerouslySetInnerHTML={{
                __html: alertText,
              }}></DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button variant="yellow" style={{padding: "0 50px"}} onClick={handleExit}>
              Выйти
            </Button>
            <Button style={{padding: "0 50px"}} onClick={handleBack}>
              Остаться
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
