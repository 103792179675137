import {RemoteData} from "./RemoteData";

export const createReducer = (routine: any, hydratePath = "", useCache = false) => {
  const initialState = RemoteData.initial();

  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      case routine.FULFILL:
        return initialState;
      case routine.SUCCESS:
        return RemoteData.success(action.payload);
      case routine.FAILURE:
        return RemoteData.failure(action.payload);
      case routine.REQUEST:
        return RemoteData.request();
      default:
        return state;
    }
  };
  return reducer;
};
