import "./style.scss";

import {Field, Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {userForgotRoutine} from "../../redux/routines/userRoutine";
import {loginSchema} from "./schema";

type Props = {};

export const ForgotPage = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userForgot = useSelector((state: any) => state.user.userForgot);

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    if (userForgot.isSuccess) {
      setAlertTitle("Успех");
      setAlertText("Новый пароль отправлен вам на почту");
      showAlert(true);
    }
    if (userForgot.isFailure) {
      setAlertTitle("Ошибка");
      setAlertText(userForgot.errorMessage);
      showAlert(true);
    }
  }, [userForgot]);

  useEffect(() => {
    return () => {
      dispatch(userForgotRoutine.fulfill());
    };
  }, []);

  const onClose = () => showAlert(false);

  return (
    <div className="container" id="ForgotPage">
      <h1>Сбросить пароль</h1>
      <p>Пожалуйста, введите адрес электронной почты. Вы получите ссылку для создания нового пароля по электронной почте</p>
      <Formik
        initialValues={{email: ""}}
        validationSchema={loginSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          dispatch(userForgotRoutine(values));
        }}>
        {({errors, touched}) => (
          <Form className="login-form">
            <Field id="email" name="email" placeholder="E-mail" component={Input} />

            <Button type="submit">Сбросить пароль</Button>

            {Object.values(errors).length > 0 && (
              <div className="form-errors">
                {Object.values(errors).map((msg, index) => (
                  <p key={`err_${index}`}>{msg}</p>
                ))}
              </div>
            )}
          </Form>
        )}
      </Formik>
      <Dialog open={alert} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{sx: {overflowY: "unset"}}}>
        <div style={{overflowY: "unset"}}>
          <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{alertText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton
              color="primary"
              sx={{
                position: "absolute",
                right: "-20px",
                top: "-20px",
                backgroundColor: "lightgray",
                color: "gray",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
