import { createSaga } from '../../utils/createSaga';
import {
    externalGorloRoutine, externalHripyRoutine, externalPolisRoutine
} from '../routines/externalRoutine';

export default function* externalSaga() {
  yield createSaga(externalPolisRoutine, "POST", "api/external/getPolis", {
    withFile: true,
  });

  yield createSaga(externalGorloRoutine, "POST", "api/external/getGorlo", {
    withFile: true,
  });

  yield createSaga(externalHripyRoutine, "POST", "api/external/getHripy", {
    withFile: true,
  });
}
