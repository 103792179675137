import { createSaga } from '../../utils/createSaga';
import {
  anamnezDiagnozRoutine,
  anamnezLifeRoutine,
  anamnezRoutine,
  userAnamnezRoutine,
  userAnamnezUpdateRoutine,
} from '../routines/anamnezRoutine';

export default function* anamnezSaga() {
  yield createSaga(anamnezRoutine, "GET", "api/anamnez");
  yield createSaga(anamnezLifeRoutine, "GET", "api/anamnez");
  yield createSaga(anamnezDiagnozRoutine, "GET", "api/anamnez");
  yield createSaga(userAnamnezRoutine, "POST", "api/anamnez/save");
  yield createSaga(userAnamnezUpdateRoutine, "POST", "api/anamnez/update");
}
